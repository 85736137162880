import React from 'react';
import classNames from 'classnames';

import PriceButton, {
  PriceButtonColors,
} from 'components/PriceButton/PriceButton';

import styles from './TableViewCell.module.scss';

interface TableViewCellProps {
  thumbnail?: React.ReactNode;
  leftTitle?: string;
  leftSubtitle?: string;
  title: string;
  subtitle?: React.ReactElement | string;
  badge?: React.ReactNode;
  price?: string;
  isActive?: boolean;
  isSmall?: boolean;
  isSearchBar?: boolean;
  rightColors?: PriceButtonColors;
}

const TableViewCell = ({
  thumbnail,
  leftTitle,
  leftSubtitle,
  title,
  subtitle,
  badge,
  price,
  isActive,
  isSmall,
  isSearchBar,
  rightColors,
}: TableViewCellProps) => {
  const right = rightColors ? (
    <PriceButton
      priceButtonColors={rightColors}
      price={price}
      className={styles['price-button']}
    />
  ) : (
    price
  );

  return (
    <div
      className={classNames(styles['table-view-cell'], {
        [styles.active]: isActive,
        [styles.small]: isSmall,
        [styles['search-bar']]: isSearchBar,
        [styles['right-colors']]: rightColors,
      })}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          {thumbnail || (
            <>
              <span className={styles.title}>{leftTitle}</span>
              <p className={styles.subtitle}>{leftSubtitle}</p>
            </>
          )}
        </div>
        <div className={styles.main}>
          <span className={styles.title}>{title}</span>
          <div className={styles.subtitle}>
            {price && isSearchBar && (
              <>
                <span className={styles['subtitle-price']}>{price}</span>
                &nbsp;&middot;&nbsp;
              </>
            )}
            {subtitle}
          </div>
          {badge}
        </div>
        {right && !isSearchBar && <span className={styles.right}>{right}</span>}
      </div>
    </div>
  );
};

export default TableViewCell;
