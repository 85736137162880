import React from 'react';

import { BTN_TYPES, Click, ClickTracker, PAYLOAD } from 'analytics';
import Link from 'components/Link/Link';
import { MOBILE_VIEW, MobileEventView } from 'types/event';

import MapListLabel from './MapListLabel';

import styles from './MapListSwitch.module.scss';

const VIEW_PROPERTIES = {
  [MOBILE_VIEW.MAP]: {
    clickTracker: new ClickTracker().interaction(Click.INTERACTIONS.BUTTON(), {
      [PAYLOAD.TYPE]: BTN_TYPES.LIST_VIEW,
    }),
  },
  [MOBILE_VIEW.LIST]: {
    clickTracker: new ClickTracker().interaction(
      new ClickTracker().interaction(Click.INTERACTIONS.SEAT_MAP())
    ),
  },
};

interface MapViewSwitchProps {
  onClick: VoidFunction;
  view: MobileEventView;
}

const MapListSwitch = ({ onClick, view }: MapViewSwitchProps) => {
  const currentView = view.isList ? MOBILE_VIEW.LIST : MOBILE_VIEW.MAP;
  const views = [MOBILE_VIEW.LIST, MOBILE_VIEW.MAP];

  return (
    <div className={styles.container}>
      <Link
        className={styles.button}
        clickTracker={VIEW_PROPERTIES[currentView].clickTracker}
        onClick={onClick}
      >
        <MapListLabel isActive={view.type !== views[0]}>
          {views.map((label) => (
            <div key={label} className={styles.pill}>
              {label}
            </div>
          ))}
        </MapListLabel>
      </Link>
    </div>
  );
};

export default MapListSwitch;
