import { Action } from '@reduxjs/toolkit';

import { LocaleConfig } from 'types';

import { fetchLocaleConfigsSuccess } from './actions';

export interface LocaleConfigsState {
  localeConfig: Record<string, LocaleConfig | undefined>;
}

export const initialState: LocaleConfigsState = {
  localeConfig: {},
};

export default function reducer(
  state: LocaleConfigsState = initialState,
  action: Action
): LocaleConfigsState {
  if (fetchLocaleConfigsSuccess.match(action)) {
    const localeConfigKey = action.payload.localeConfigKey;
    const response = action.payload.response;

    return {
      ...state,
      localeConfig: {
        ...state.localeConfig,
        [localeConfigKey]: response,
      },
    };
  }
  return state;
}
