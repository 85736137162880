import React from 'react';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import PurchaseHeading from 'pages/PurchaseV3/components/PurchaseHeading';

import {
  Form,
  PhoneNumber,
  Validators,
} from '../../../CheckoutV3/components/Forms';

interface PurchaseUserPhoneProps {
  onSubmit: (phoneNumber: string) => void;
}

interface Values {
  phoneNumber: string;
}

const UserPhoneFormValidationSchema = Validators.schema({
  phoneNumber: Validators.phoneNumber({
    label: 'phone number',
    required: true,
  }),
});

function PurchaseUserPhone({ onSubmit }: PurchaseUserPhoneProps) {
  const analytics = useAnalyticsContext();

  const formId = 'user-phone-form';

  function handleSubmit(form: Values) {
    analytics.track(
      new Click(
        new ClickTracker()
          .interaction(Click.INTERACTIONS.CONTINUE_BUTTON())
          .sourcePageType(Click.SOURCE_PAGE_TYPES.ADD_PHONE_NUMBER())
          .json()
      )
    );
    onSubmit(form.phoneNumber);
  }

  return (
    <>
      <PurchaseHeading
        heading="What's Your Phone Number?"
        subheading="We'll only call in cases of emergency and we won't share your
        information."
      />

      <Form
        id={formId}
        initialValues={{ phoneNumber: '' }}
        onSubmit={handleSubmit}
        validationSchema={UserPhoneFormValidationSchema}
        submitText="Continue"
      >
        <PhoneNumber id="user-phone-number" name="phoneNumber" />
      </Form>
    </>
  );
}

export default PurchaseUserPhone;
