import React from 'react';
import classNames from 'classnames';
import Tag from 'ui/Tag/Tag';

import DealTag from 'components/DealTag';
import Image from 'components/Image/Image';
import MLBBadge from 'components/MLBBadge/MLBBadge';
import Disclosures from 'components/Modals/DisclosuresModal/Disclosures';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import { DiscountIcon } from 'icons';
import { Disclosure, Listing } from 'models';
import { useAppSelector } from 'store';
import { selectAllDeals } from 'store/modules/resources/resource.selectors';

import { formatPriceWithComma } from '../../../../utils/number';

import styles from './ListingFocusedCard.module.scss';

export interface ListingFocusedCardProps {
  listing: Listing;
  allDisclosures: Record<string, Disclosure>;
  isAllInPrice: boolean;
  isMLBEvent: boolean;
  isMobileVariant?: boolean;
  showListingSource?: boolean;
  bordered?: boolean;
}

// only show savings in dollars if the savings amount is greater than or equal
// to this value in whole dollars, otherwise continue showing percentage
const SHOW_DISCOUNT_IN_DOLLARS_MINIMUM = 10;

export const ListingFocusedCard = ({
  listing,
  allDisclosures,
  isAllInPrice,
  isMLBEvent,
  isMobileVariant = false,
  showListingSource = false,
  bordered = false,
}: ListingFocusedCardProps) => {
  const allDeals = useAppSelector(selectAllDeals);

  const ticketPrice = isAllInPrice ? listing.preTaxTotal : listing.prefeePrice;

  const isWidePriceLabel =
    listing.isDiscounted() && ticketPrice.toString().length > 3;

  const deal = listing.dealType && allDeals[listing.dealType];

  function getValueTag() {
    if (listing.isDiscounted()) {
      const savingsAmount = listing.getSavingsAmount();

      const priceMarkdown =
        savingsAmount >= SHOW_DISCOUNT_IN_DOLLARS_MINIMUM ? (
          <span>
            <span className={styles['dollar-sign']}>$</span>
            {formatPriceWithComma(savingsAmount)} OFF
          </span>
        ) : (
          <>{listing.getDiscountPercent()}% OFF</>
        );

      return (
        <Tag
          title={priceMarkdown}
          icon={<DiscountIcon width={12} height={12} />}
          size="small"
          bordered
        />
      );
    }

    if (deal?.valueMessage) {
      return <Tag title={deal.valueMessage.tag} size="small" bordered />;
    }

    return null;
  }

  const coloredBorderStyles = deal?.colorGradient
    ? {
        background: `linear-gradient(${deal.colorGradient.join(', ')}) border-box`,
        border: `1.5px solid transparent`,
      }
    : {};

  const finalPriceStyle: React.CSSProperties = deal?.colorGradient
    ? { color: deal.colorGradient[1] }
    : {};

  return (
    <div
      className={classNames(styles['card-container'], {
        [styles['card-container-mobile']]: isMobileVariant,
        [styles['bordered']]: bordered,
      })}
      style={coloredBorderStyles}
    >
      <Image
        className={styles.image}
        {...listing.getImageOptions()}
        lazyLoad={false}
      />

      <div className={styles['deal-badge']}>
        {deal && <DealTag deal={deal} />}
      </div>

      <div className={styles['content-column-left']}>
        {listing.hasZoneTicketDisclosure && <ZoneTag />}
        {isMLBEvent && <MLBBadge />}
        <h3 className={styles['section-name']}>{listing.sectionGroup}</h3>
        <div className={styles['seat-details-container']}>
          <span
            className={classNames(styles['seat-details'], {
              [styles['wide-price-label']]: isWidePriceLabel,
            })}
          >
            {listing.section}, Row {listing.row}
          </span>

          <Disclosures
            disclosures={listing.disclosures}
            allDisclosures={allDisclosures}
            onlyIcon
          />
        </div>
        {showListingSource && (
          <span
            className={classNames(styles['listing-source'], {
              [styles['wide-price-label']]: isWidePriceLabel,
            })}
          >
            Ticket Source: {listing.source}
          </span>
        )}
      </div>

      <div className={styles['content-column-right']}>
        {getValueTag()}

        {isAllInPrice && (
          <span className={styles['all-in-pricing']}>All-In</span>
        )}

        <div className={styles['price-info']}>
          {listing.isDiscounted() && (
            <TicketPrice
              className={styles['price-before-discount']}
              isPriceCrossedOut
              price={ticketPrice + listing.getSavingsAmount()}
            />
          )}
          <TicketPrice
            price={ticketPrice}
            className={classNames({
              [styles['final-price']]: deal?.colorGradient,
            })}
            style={finalPriceStyle}
          />
        </div>
      </div>

      <span className={styles['card-gradient']} />
    </div>
  );
};

export default ListingFocusedCard;
