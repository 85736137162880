import { LoaderFunction } from 'react-router-dom';

import { Store } from 'store';

export type DataLoaderContext = { store: Store; variants: unknown };
export type DataLoader = (context: DataLoaderContext) => LoaderFunction;

/**
 * Wraps a data loader function to catch any errors and return null instead.
 * We still want to catch and handle errors in the loader functions, this is
 * just a fallback to prevent the app from crashing if an error is thrown.
 */
export function dataLoaderWrapper(loader: LoaderFunction) {
  return async (...loaderArgs: Parameters<LoaderFunction>) => {
    try {
      // loader must be awaited to catch errors in the try block
      return await loader(...loaderArgs);
    } catch (error) {
      console.error('Error in loader function', error);
      return null;
    }
  };
}
