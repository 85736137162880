import React from 'react';
import classNames from 'classnames';
import { ValueOf } from 'type-fest';

import DealsSlider from 'components/Deals/DealsSlider/DealsSlider';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { Deal, Disclosure, FullEvent, Listing, Performer } from 'models';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';
import ListingPromoBanner from 'pages/Event/components/ListingPromoBanner/ListingPromoBanner';
import { EVENTBAR_VIEWS } from 'pages/Event/Event.constants';
import { ListingV3DisplayGroupCollection } from 'store/modules/listingsV3/selectors';

import ExpiredEvent from '../ExpiredEvent';
import Listings from '../Listings';

import styles from './EventBar.module.scss';

interface EventBarProps {
  onListingHover: (listing: Listing) => void;
  onListingsTouchStart: React.TouchEventHandler<HTMLDivElement>;
  onListingsTouchMove: React.TouchEventHandler<HTMLDivElement>;
  isCheckout: boolean;
  children: React.ReactNode;
  schedule: FullEvent[];
  relatedPerformers: Performer[];
  fullEvent: FullEvent;
  listingsInDisplayGroups: ListingV3DisplayGroupCollection[];
  sidebarView: ValueOf<typeof EVENTBAR_VIEWS>;
  allDisclosures: Partial<Record<string, Disclosure>>;
  allDeals: Partial<Record<string, Deal>>;
  isHarmonyPlusOverlay: boolean;
}

function EventBar({
  onListingHover,
  onListingsTouchStart,
  onListingsTouchMove,
  isCheckout,
  children,
  schedule,
  relatedPerformers,
  fullEvent,
  listingsInDisplayGroups,
  sidebarView,
  allDisclosures,
  allDeals,
  isHarmonyPlusOverlay,
}: EventBarProps) {
  const isDesktop = useMediaQuery(device.up.lg);
  const renderListings = () => {
    const eventBanner = fullEvent.event.banner;

    return (
      <>
        <ListingPromoBanner
          headline={eventBanner.headline}
          subtitle={eventBanner.subtitle}
          emoji={eventBanner.emoji}
          eventId={fullEvent.id}
        />

        {listingsInDisplayGroups.map((displayGroup) => {
          if (displayGroup.listings.length === 0) {
            return null;
          }

          if (displayGroup.type === 'carousel') {
            return (
              <DealsSlider
                key={displayGroup.slug}
                header={displayGroup.title}
                listings={displayGroup.listings}
                fullEvent={fullEvent}
                dealType={displayGroup.deal}
                onListingHover={onListingHover}
                allDisclosures={allDisclosures}
                allDeals={allDeals}
              />
            );
          }

          if (displayGroup.type === 'deals_list') {
            return (
              <React.Fragment key={displayGroup.slug}>
                <h3 className={styles['heading']}>More Great Deals</h3>
                <div className={styles['deals-section-listings']}>
                  <div className={styles['listings']}>
                    {displayGroup.listings.map((listing, index) => (
                      <ListingCard
                        key={listing.id}
                        listingIndex={index}
                        fullEvent={fullEvent}
                        listing={listing}
                        onHover={onListingHover}
                        allDisclosures={allDisclosures}
                      />
                    ))}
                  </div>
                </div>
                {/* display heading for next list only if deals_list is present */}
                <h3 className={styles['heading']}>All Tickets</h3>
              </React.Fragment>
            );
          }

          return (
            <Listings
              key={displayGroup.slug}
              fullEvent={fullEvent}
              allDeals={allDeals}
              allDisclosures={allDisclosures}
              onListingHover={onListingHover}
              listings={displayGroup.listings}
            />
          );
        })}
      </>
    );
  };

  const renderSidebarContent = () => {
    if (isHarmonyPlusOverlay && isDesktop) {
      return (
        <>
          {children}
          {renderListings()}
        </>
      );
    }

    switch (sidebarView) {
      case EVENTBAR_VIEWS.LISTING_PURCHASE:
        return <>{children}</>;
      case EVENTBAR_VIEWS.EXPIRED:
        return (
          <ExpiredEvent
            schedule={schedule}
            relatedPerformers={relatedPerformers}
          />
        );
      case EVENTBAR_VIEWS.LISTINGS:
      default:
        return renderListings();
    }
  };

  return (
    <div
      onTouchStart={onListingsTouchStart}
      onTouchMove={onListingsTouchMove}
      className={classNames(styles['event-bar'], {
        [styles.checkout]: isCheckout,
      })}
    >
      {renderSidebarContent()}
    </div>
  );
}

export default EventBar;
