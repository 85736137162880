import { getMetros } from 'services/metro/metro.service';
import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import {
  allDisclosuresSelector,
  selectAllDeals,
  selectMetros,
} from 'store/modules/resources/resource.selectors';
import { isObjectEmpty } from 'utils/objects';

import { REDUCERS } from '../reducer.constants';

// *************** ACTION TYPES *******************

export const RESOURCE_ACTION_TYPES = generateActionTypes(
  [
    // solo actions
    'FETCH_METROS',
    'FETCH_DISCLOSURES',
    'FETCH_DEALS',
  ],
  REDUCERS.RESOURCES,
  ['', '_SUCCESS', '_FAIL']
);

export const fetchMetros = () => (dispatch, getState) => {
  const globalState = getState();
  const metros = selectMetros(globalState);

  if (!isObjectEmpty(metros)) {
    return Promise.resolve();
  }

  return dispatch({
    types: [
      RESOURCE_ACTION_TYPES.FETCH_METROS,
      RESOURCE_ACTION_TYPES.FETCH_METROS_SUCCESS,
      RESOURCE_ACTION_TYPES.FETCH_METROS_FAIL,
    ],
    promise: () => getMetros(),
  });
};

// fetchDisclosures fetch the list of available disclosures from the backend
export const fetchDisclosures = () => (dispatch, getState) => {
  const globalState = getState();
  const disc = allDisclosuresSelector(globalState);

  if (!isObjectEmpty(disc)) {
    return Promise.resolve();
  }

  return dispatch({
    types: [
      RESOURCE_ACTION_TYPES.FETCH_DISCLOSURES,
      RESOURCE_ACTION_TYPES.FETCH_DISCLOSURES_SUCCESS,
      RESOURCE_ACTION_TYPES.FETCH_DISCLOSURES_FAIL,
    ],
    promise: (httpClient) => httpClient.request({ path: '/v1/disclosures' }),
  });
};

// fetchDeals retrieves an up-to-date list of supported deal types for featured listings from BE
export const fetchDeals = () => (dispatch, getState) => {
  const globalState = getState();
  const deals = selectAllDeals(globalState);

  if (!isObjectEmpty(deals)) {
    return Promise.resolve();
  }

  return dispatch({
    types: [
      RESOURCE_ACTION_TYPES.FETCH_DEALS,
      RESOURCE_ACTION_TYPES.FETCH_DEALS_SUCCESS,
      RESOURCE_ACTION_TYPES.FETCH_DEALS_FAIL,
    ],
    promise: (httpClient) =>
      httpClient.request({
        path: '/v1/deals',
      }),
  });
};
