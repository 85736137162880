import { createAction } from '@reduxjs/toolkit';
import type { AsyncThunk } from 'store';

import {
  getListingsV3,
  GetListingsV3Params,
} from 'services/listings/listings.service';
import { GetListingsV3Response } from 'types';

import { appConfigSelector } from '../app/app.selectors';

import { getSuggestedQuantity } from './utils';

export const fetchListingsV3Request = createAction<GetListingsV3Params>(
  'Listings/FetchListingsV3Request'
);
export const fetchListingsV3Success = createAction<{
  eventId: string;
  response: GetListingsV3Response;
}>('Listings/FetchListingsV3Success');
export const fetchListingsV3Failure = createAction<unknown>(
  'Listings/FetchListingsV3Failure'
);

/**
 * Synchronously update Listings V3 params without re-fetching listings
 */
export const updateListingsV3Params = createAction<GetListingsV3Params>(
  'Listings/UpdateListingsV3Params'
);

export const setZoomLevel = createAction<number>('Listings/SetZoomLevel');

export const setIsListMapHarmonyEnabled = createAction<boolean>(
  'Listings/SetIsListMapHarmonyEnabled'
);

/**
 * Thunk action to fetch Listings V3 from the server and load them into the
 * store
 */
export function fetchListingsV3(params: GetListingsV3Params): AsyncThunk {
  return async (dispatch, getState, { mobileApi }) => {
    const state = getState();
    const { isMobile } = appConfigSelector(state);

    dispatch(fetchListingsV3Request(params));

    try {
      const getListingsV3Response = await getListingsV3({
        mobileApi,
        params,
        isMobile,
      }).then((response) => {
        // if we get a non-null response that has available lots but does not
        // have the requested quantity in `available_lots`, we will re-request
        // with one of the available quantities.
        if (
          !!response?.available_lots.length &&
          !response.available_lots.includes(params.quantity)
        ) {
          const retryParams = {
            ...params,
            quantity: getSuggestedQuantity(response.available_lots),
          };

          dispatch(updateListingsV3Params(retryParams));

          return getListingsV3({
            mobileApi,
            params: retryParams,
            isMobile,
          });
        }

        return response;
      });

      if (!getListingsV3Response) {
        throw new Error('null response from GET /v3/listings request');
      }

      dispatch(
        fetchListingsV3Success({
          eventId: params.eventId,
          response: getListingsV3Response,
        })
      );
    } catch (error) {
      dispatch(fetchListingsV3Failure(error));
    }
  };
}
