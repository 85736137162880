/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import Butter from 'buttercms';
import { AppProvider } from 'contexts/AppContext';
import BrazeProvider from 'contexts/BrazeContext';
import { GooglePayProvider } from 'contexts/GooglePayContext';
import { ReCaptchaProvider } from 'contexts/ReCaptchaContext';
import Pusher from 'pusher-js';

import config from 'config/config';
import {
  envIsDatadogEnv,
  envIsLikeDev,
  envIsSentryEnv,
} from 'config/environments';
import getRoutes from 'routes/routes';
import { HttpClient } from 'services/HttpClient/HttpClient';
import VariantProvider from 'services/variants/VariantProvider';
import { createStore, ReduxProvider } from 'store';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import { setForterToken } from 'store/modules/user/actions';
import { initializeApplePay } from 'store/modules/userPurchases/applePay/applePay';
import { beforeSend } from 'utils/sentryUtils';

import { version } from '../package.json';

import { AnalyticsProvider } from './analytics';

import 'styles/common/base.scss';

if (envIsSentryEnv(config.env)) {
  Sentry.init({
    dsn: 'https://d34125aec97d44b6959ff62f3a287a66@o193734.ingest.sentry.io/1823111',
    environment: config.env,
    ignoreErrors: [/UnhandledRejection: Non-Error promise rejection/i],
    beforeSend,
    attachStackTrace: true,
    integrations: [
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: false,
        history: true,
        sentry: true,
        xhr: false,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserProfilingIntegration(),
    ],
    tracePropagationTargets: ['localhost', /\.gametime\.co$/],
    tracesSampleRate: config.SENTRY_TRACING_SAMPLE_RATE,
    profilesSampleRate: config.SENTRY_TRACING_SAMPLE_RATE,
  });
}

const butterPreviewMode = envIsLikeDev(config.env);

const store = createStore(
  {
    mobileApi: new HttpClient(config.MOBILE_API_URL),
    butter: Butter(config.BUTTER_CMS_TOKEN, butterPreviewMode),
  },
  window.__data.redux
);

const { appContext: appContextState, variants: initialVariantData } =
  window.__data;

const environment = window.__environment;

delete window.__data;
delete window.__environment;

const appConfig = appConfigSelector(store.getState());

window.appConfig = appConfig; // For Google Tag Manager
window.pusher = new Pusher(config.PUSHER_KEY, {
  cluster: config.PUSHER_CLUSTER,
});

const datadog = appContextState.datadogSession;
store.dispatch(initializeApplePay());

// Forter TTI (Token Trigger Integration)
document.addEventListener('ftr:tokenReady', (evt) => {
  const token = evt.detail;
  store.dispatch(setForterToken(token));
});

if (envIsDatadogEnv(config.env)) {
  datadogRum.init({
    applicationId: config.DATADOG_APP_ID,
    clientToken: config.DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'web-consumer',
    allowedTracingUrls: [/https:\/\/.*\.gametime\.co/],
    env: config.env,
    version: version,
    sessionSampleRate: datadog.sampleRate,
    sessionReplaySampleRate: datadog.recordRate,
    trackUserInteractions: true,
    enableExperimentalFeatures: ['clickmap'],
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

if (__DEVELOPMENT__ || appConfig.isInternal) {
  window.React = React; // enable debugger
  window.store = store;
  console.info('Build Time:', new Date(__BUILD_TIME__));
  console.info('sessionSampleRate:', datadog.sampleRate);
  console.info('sessionReplaySampleRate:', datadog.recordRate);
}

function hydrate(_router) {
  hydrateRoot(
    document.getElementById('content'),
    <HelmetProvider>
      <VariantProvider
        environment={environment?.name}
        initialValues={initialVariantData}
        version={environment?.version}
      >
        <GooglePayProvider>
          <ReCaptchaProvider reCaptchaKey={config.GOOGLE_RE_CAPTCHA_KEY}>
            <ReduxProvider store={store}>
              <AppProvider store={appContextState}>
                <BrazeProvider>
                  <AnalyticsProvider appContext={appContextState}>
                    <RouterProvider router={_router} />
                  </AnalyticsProvider>
                </BrazeProvider>
              </AppProvider>
            </ReduxProvider>
          </ReCaptchaProvider>
        </GooglePayProvider>
      </VariantProvider>
    </HelmetProvider>
  );
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  getRoutes({ store, variants: initialVariantData })
);

hydrate(router);

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept('./routes/routes', () => {
    const nextRoutes = require('./routes/routes').default;
    const router = createBrowserRouter(
      nextRoutes({ store, variants: initialVariantData })
    );
    hydrate(router);
  });

  import.meta.webpackHot.accept('./store/modules/reducer', () => {
    const nextReducer = require('./store/modules/reducer').default;
    store.replaceReducer(nextReducer);
  });
}
