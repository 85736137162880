import _minBy from 'lodash/minBy';
import { createSelector } from 'reselect';

import { Deal, Disclosure } from 'models';
import { currentLocationSelector } from 'store/modules/app/app';
import { selectFullEventById } from 'store/modules/data/FullEvents/selectors';
import { selectListingById } from 'store/modules/listingsV3/selectors';
import { formatUrl } from 'utils/format';
import { distanceBetweenLocations } from 'utils/geo';
import { isObjectEmpty } from 'utils/objects';

import { selectUserIPGeolocation } from '../user/user.selectors';

import { RESTRICTED_METROS } from './constants';

/* LISTINGS */
export const isPurchasableListingSelector = (state, props) => {
  const listing = selectListingById(
    state,
    props.listingId || props.params.listingId
  );
  return (
    !isObjectEmpty(listing) &&
    listing.lotsList[0] &&
    selectFullEventById(state, props.eventId || props.params.eventId).isValid()
  );
};

export const listingImagePropsSelector = (listing, venueName) => {
  const alias = `${listing.sectionGroup} ${listing.section}`;
  const alt = `Seat view from ${alias}${venueName ? ` at ${venueName}` : ''}`;
  return {
    seoPrefix: formatUrl(venueName),
    seoPostfix: formatUrl(alias),
    alt,
    src: listing.viewUrl,
  };
};

/**
 * @typedef {import('store').RootState} RootState
 * @typedef {import('types').Deal} DealData
 * @typedef {import('types').FullDisclosure} DisclosureData
 */

/**
 * @type {(state: RootState) => DisclosureData[] | undefined}
 */
const allDisclosuresState = (state) =>
  state?.resources?.allDisclosures?.disclosures;

/**
 * Disclosures
 * Create a dictionary of available disclosure options from the state data
 * slugs are used as the keys because they are the primary key in the db
 * @type {(state: RootState) => Partial<Record<string, Disclosure>>}
 */
export const allDisclosuresSelector = createSelector(
  [allDisclosuresState],
  (disclosures = []) => {
    return disclosures.reduce((allDisclosures, disclosure) => {
      allDisclosures[disclosure.slug] = new Disclosure(disclosure);
      return allDisclosures;
    }, {});
  }
);

/**
 * @type {(state: RootState) => DealData[] | undefined}
 */
const selectDeals = (state) => state.resources.allDeals?.deals;

/**
 * @type {(state: RootState) => Partial<Record<string, Deal>>}
 */
export const selectAllDeals = createSelector([selectDeals], (deals = []) => {
  return deals.reduce((allDeals, deal) => {
    allDeals[deal.slug] = new Deal(deal);
    return allDeals;
  }, {});
});

/* Metros */
export const selectMetros = (state) => state.resources.metros;

export const selectMetro = (state, metroId) => selectMetros(state)[metroId];

export const selectAllMetros = createSelector(selectMetros, (metros) =>
  Object.values(metros).sort((a, b) => a.name.localeCompare(b.name))
);

export const selectClosestMetro = createSelector(
  selectAllMetros,
  (state) => selectUserIPGeolocation(state),
  (allMetros, ipGeolocation) =>
    _minBy(allMetros, (metro) =>
      distanceBetweenLocations(
        {
          lat: ipGeolocation.ll[0],
          lon: ipGeolocation.ll[1],
        },
        {
          lat: metro.location.coordinates.lat,
          lon: metro.location.coordinates.lon,
        }
      )
    )
);

export function selectLastVisitedMetro(state) {
  return selectMetro(state, state.userPreference.lastVisitedMetro);
}

export function selectCurrentMetro(state) {
  const currentLocation = currentLocationSelector(state);

  if (!currentLocation) {
    return;
  }

  return selectMetro(state, currentLocation);
}

export const selectUserMetro = (state) =>
  selectLastVisitedMetro(state) || selectCurrentMetro(state);

export const isRestrictedMetro = (state) => {
  const loc = selectCurrentMetro(state);

  if (!loc) return false;

  return loc && RESTRICTED_METROS.has(loc.id);
};
