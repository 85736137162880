import { getYear } from 'date-fns';

import { FullEvent } from 'models';
import { DATE_FORMAT_M_D, DATE_FORMAT_M_D_YY } from 'utils/datetime';

import { EVENT_CELL_TYPES } from './constants';

export function getDateFormat(fullEvent: FullEvent) {
  const eventYear = fullEvent.year;

  if (eventYear === 'TBD') {
    return '';
  }

  const currentDate = new Date();
  const currentYear = getYear(currentDate);

  if (currentYear === eventYear) {
    return DATE_FORMAT_M_D;
  }

  return DATE_FORMAT_M_D_YY;
}

export function getTitle(fullEvent: FullEvent, type: string) {
  if (type === EVENT_CELL_TYPES.SHORT) {
    return fullEvent.getShortName('at');
  }

  if (type === EVENT_CELL_TYPES.MEDIUM) {
    return fullEvent.getMediumName('at');
  }

  return fullEvent.getName('at');
}
