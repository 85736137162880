import { JsonParseError } from 'utils/errors';

export async function parseJsonResponseBody<R>(jsonString: string): Promise<R> {
  try {
    return JSON.parse(jsonString) as R;
  } catch (error) {
    if (error instanceof Error) {
      console.info(
        'Error details:',
        JSON.stringify(
          {
            errorName: error.name,
            errorMessage: error.message || 'No message available',
            errorStack: error.stack || 'No stack trace available',
            responseBody: jsonString,
          },
          null,
          2
        )
      );
    }

    throw new JsonParseError('Failed to parse JSON response', { cause: error });
  }
}
