import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ValueOf } from 'type-fest';

import {
  Click,
  ClickTracker,
  InsuranceRendered,
  useAnalyticsContext,
} from 'analytics';
import { InsuranceInfoCollection } from 'analytics/events/InsuranceInfoCollection';
import config from 'config/variants';
import { FullEvent, Listing } from 'models';
import { postInsuranceQuote } from 'services/insurance/insurance.service';
import { useVariantService } from 'services/variants';
import { useAppDispatch, useAppSelector } from 'store';
import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import { extendedPurchaseSelector } from 'store/modules/purchase/purchase.selectors';
import { updateUser } from 'store/modules/user/typedActions';
import {
  selectUserDetails,
  userPromosForListingSavingsSelector,
} from 'store/modules/user/user.selectors';
import {
  exhaustiveSwitchGuard,
  InsuranceEligibleResult,
  InsuranceInfo,
  InsuranceInput,
  InsuranceQuoteDetails,
} from 'types';
import { FormSubmitError, HttpError } from 'utils/errors';
import { searchStringToQueryObject } from 'utils/url';

import {
  BookingStatus,
  Insurance,
  InsuranceQuote,
  UpdateQuoteParams,
} from './types';

/**
 * Divides total quote price by ticket quantity, round up to the next whole
 * cent, and return price per ticket in dollars.
 */
export function getInsuranceUnitPrice(
  details: InsuranceQuoteDetails,
  quantity: number
) {
  return Math.ceil(details.total_price / quantity) / 100;
}

/**
 * Returns the total quote price in dollars.
 */
export function getInsuranceTotalPrice(
  details: InsuranceQuoteDetails | InsuranceInfo
) {
  return details.total_price / 100;
}

/**
 * Returns whether the user has opted in to insurance coverage based on the
 * booking status in the completed purchase.
 *
 * - No status indicates the purchase was made without insurance, implicitly
 *   opting out.
 * - Statuses "failed" and "system_error" indicate the user did opt-in to
 *   insurance coverage but the insurance purchase failed.
 */
export function getIsInsuranceOptInStatus(status: BookingStatus | undefined) {
  const optInStatus: BookingStatus[] = [
    'confirmed',
    'pending_booking',
    'failed',
    'system_error',
  ];

  return !!status && optInStatus.includes(status);
}

/**
 * Return status label and message for insurance coverage in order confirmation
 */
export function getInsuranceStatus(status?: BookingStatus) {
  if (!status) return null;

  switch (status) {
    case 'pending_booking': {
      return {
        label: 'pending' as const,
        message:
          "Your ticket protection order is processing. You'll receive an email once it's complete.",
      };
    }
    case 'system_error':
    case 'failed': {
      return {
        label: 'cancelled' as const,
        message:
          'Your ticket protection order failed to process. For help, email support@xcover.com.',
      };
    }
    case 'confirmed':
    case 'opted_out':
    case 'pending_opt_out': {
      return {
        label: null,
        message: null,
      };
    }
    default: {
      return exhaustiveSwitchGuard(status);
    }
  }
}

/**
 * Estimate eligibility when A/B test is not enabled for tracking purposes.
 * Note that the following does not account for location since we won't have it.
 */
const estimateInsuranceEligible = (
  adjustedTotal: number,
  eventDatetimeUtc: string
): boolean => {
  // If total price is less than $50, the purchase is not eligible for insurance
  if (adjustedTotal < 50) {
    return false;
  }

  const TWENTY_FOUR_HOURS_FROM_NOW = Date.now() + 24 * 60 * 60 * 1000;
  const eventStart = new Date(eventDatetimeUtc).getTime();

  // To be eligible for insurance, the event must be at least 24 hours from now
  return eventStart > TWENTY_FOUR_HOURS_FROM_NOW;
};

interface InsuranceProviderProps {
  children: React.ReactNode;
  listing?: Listing;
  event: FullEvent;
}

export const InsuranceContext = createContext<Insurance | undefined>(undefined);

function InsuranceProvider({
  children,
  listing,
  event,
}: InsuranceProviderProps) {
  /*
   * When transitioning from "Someone beat you..." message back to listings page, `listing` is `undefined`
   * and throws multiple cannot access property of undefined errors.
   */
  if (!listing) {
    return children;
  }

  const dispatch = useAppDispatch();
  const location = useLocation();
  const analytics = useAnalyticsContext();
  const variantService = useVariantService();

  const extendedPurchase = useAppSelector((state) =>
    extendedPurchaseSelector(state, {
      listing,
    })
  );

  const listingPromoSavings = useAppSelector(
    userPromosForListingSavingsSelector
  );
  const authUser = useAppSelector(selectUserDetails);
  const isEnabled = variantService.hasFeature(config.gates.insurance);

  const [isLoadingQuote, setIsLoadingQuote] = useState(false);
  const [quote, setQuote] = useState<InsuranceQuote | null>(null);
  const [selectedPurchaseType, setSelectedPurchaseType] =
    useState<ValueOf<typeof PURCHASE_TYPE>>();
  const [isLoadingEligibility, setIsLoadingEligibility] = useState(true);
  const [adjustedTotal, setAdjustedTotal] = useState(
    extendedPurchase.totalPrice - listingPromoSavings
  );
  const [eligibility, setEligibility] =
    useState<InsuranceEligibleResult | null>(null);
  const [isSelected, setIsSelected] = useState<Insurance['isSelected']>();

  const quantity = listing.quantity;
  const eventId = event.id;
  const eventDatetimeUtc = event.event.datetimeUtc;
  const query = searchStringToQueryObject(location.search);

  // Insurance is not supported for Google Pay purchases or Affirm purchases
  const isBookingInsurance =
    selectedPurchaseType &&
    selectedPurchaseType !== PURCHASE_TYPE.GOOGLE_PAY &&
    selectedPurchaseType !== PURCHASE_TYPE.AFFIRM_PAY &&
    isSelected;

  useEffect(() => {
    // reset eligibility and quote if dependencies change
    setIsLoadingEligibility(true);
    setEligibility(null);
    setIsLoadingQuote(true);
    setQuote(null);

    if (!isEnabled) {
      analytics.track(
        new InsuranceRendered({
          quantity,
          post_fee_price: adjustedTotal,
          insurance_eligible: estimateInsuranceEligible(
            adjustedTotal,
            eventDatetimeUtc
          ),
        })
      );
      return;
    }

    if (!authUser) return;

    const zipCode =
      typeof query.insuranceZip === 'string' ? query.insuranceZip : undefined;

    // initial quote does not have a zip code, will be inferred from IP address lookup
    postInsuranceQuote({
      eventId,
      quantity,
      adjustedTotal,
      authUser: { id: authUser.id, session_token: authUser.session_token },
      zipCode,
    })
      .then((res) => {
        if (res) {
          setEligibility(res.insurance_eligibility_result);

          if (res.details) {
            setQuote({
              details: res.details,
              totalPrice: getInsuranceTotalPrice(res.details),
              unitPrice: getInsuranceUnitPrice(res.details, quantity),
              isUpdated: false,
            });
          }

          analytics.track(
            new InsuranceRendered({
              quantity,
              post_fee_price: adjustedTotal,
              insurance_eligible: res.insurance_eligibility_result.eligible,
              insurance_initial_quote:
                res.details && getInsuranceTotalPrice(res.details),
            })
          );
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingQuote(false);
        setIsLoadingEligibility(false);
      });
  }, [
    isEnabled,
    eventId,
    quantity,
    adjustedTotal,
    query.insuranceZip,
    eventDatetimeUtc,
    // only include required request parameters from authUser to avoid
    // re-requesting after user updates their contact information
    authUser?.id,
    authUser?.session_token,
  ]);

  /**
   * Update the insurance quote with the user's correct zip code.
   */
  const handleDataCollectionFormSubmit = async ({
    zipCode,
    firstName,
    lastName,
  }: UpdateQuoteParams) => {
    if (!quote) {
      throw new Error('Quote must be created before it can be updated');
    }

    setIsLoadingQuote(true);

    const errors: { [field: string]: string } = {};

    await Promise.all([
      // update the insurance quote with the user's zip code
      postInsuranceQuote({
        eventId,
        quantity,
        adjustedTotal,
        authUser,
        zipCode,
      })
        .then((res) => {
          // eligibility can change if a user updates their zip code to an
          // ineligible location
          if (!res?.insurance_eligibility_result.eligible) {
            errors.zipCode =
              res?.insurance_eligibility_result.reason ||
              'This location is not eligible for insurance coverage';
          }

          if (res?.details) {
            const totalPrice = getInsuranceTotalPrice(res.details);

            setQuote({
              isUpdated: true,
              unitPrice: getInsuranceUnitPrice(res.details, quantity),
              details: res.details,
              totalPrice,
            });

            analytics.track(
              new InsuranceInfoCollection({
                final_quote: totalPrice,
                quantity,
                post_fee_price: adjustedTotal,
              })
            );
          }
        })
        .catch((error) => {
          // the post insurance quote endpoint will return an error message in
          // the body if the zip code is invalid or the location is not found
          if (
            error instanceof HttpError &&
            typeof error.body?.error === 'string'
          ) {
            errors.zipCode = error.body.error;
          } else {
            errors.zipCode = error.message;
          }
        })
        .finally(() => setIsLoadingQuote(false)),

      // update the user's contact information with fields required for
      // booking insurance
      dispatch(
        updateUser({
          ...authUser,
          first_name: firstName,
          last_name: lastName,
        })
      ).catch(() => {
        errors.firstName =
          'There was an error updating your contact information';
      }),
    ]);

    if (Object.keys(errors).length > 0) {
      throw new FormSubmitError(errors);
    }
  };

  const adjustedTotalWithInsurance =
    isBookingInsurance && quote
      ? adjustedTotal + quote.totalPrice
      : adjustedTotal;

  const getPurchaseOptions = (): InsuranceInput | undefined => {
    if (!isEnabled || !quote?.details) {
      return undefined;
    }

    return {
      quote_id: quote.details.quote_id,
      quote_package_id: quote.details.package_id,
      postal_code: quote.details.postal_code,
      is_booking: isBookingInsurance,
    };
  };

  const getPurchaseTrackingPayload = (payment_method: string) => ({
    payment_method,
    insurance_opt_in: !!isBookingInsurance,
    final_quote: quote?.totalPrice,
    quantity,
    post_fee_price: adjustedTotal,
    insurance_eligible:
      eligibility?.eligible ??
      estimateInsuranceEligible(adjustedTotal, eventDatetimeUtc),
  });

  const getPostPurchaseTrackingPayload = () => ({
    insurance_opt_in: !!isBookingInsurance,
    insurance_eligible:
      eligibility?.eligible ??
      estimateInsuranceEligible(adjustedTotal, eventDatetimeUtc),
  });

  const trackOptInChange = (isSelected: boolean) => {
    const tracker = new ClickTracker()
      .sourcePageType(Click.SOURCE_PAGE_TYPES.CHECKOUT())
      .interaction(
        Click.INTERACTIONS.INSURANCE_OPTIONS_SELECTION({
          isSelected,
          eventId,
          listingId: listing.id,
          performerId: event.getPrimaryPerformer().id,
        })
      )
      .payload({
        quantity,
        post_fee_price: adjustedTotal,
      })
      .json();
    analytics.track(new Click(tracker));
  };

  const contextValue = {
    isEnabled,
    quote,
    handleDataCollectionFormSubmit,
    eligibility,
    isLoadingEligibility,
    isLoadingQuote,
    isSelected,
    setIsSelected,
    isBookingInsurance,
    setSelectedPurchaseType,
    setAdjustedTotal,
    trackOptInChange,
    adjustedTotalWithInsurance,
    getPurchaseOptions,
    getPurchaseTrackingPayload,
    getPostPurchaseTrackingPayload,
  };

  return (
    <InsuranceContext.Provider value={contextValue}>
      {children}
    </InsuranceContext.Provider>
  );
}

function useInsurance() {
  const context = useContext(InsuranceContext);

  if (!context) {
    throw new Error('useInsurance must be used within an InsuranceProvider');
  }

  return context;
}

export { InsuranceProvider, useInsurance };
