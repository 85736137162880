import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ValueOf } from 'type-fest';

import { ClickTracker } from 'analytics';
import BulletList from 'components/BulletList/BulletList';
import Emoji from 'components/Emoji';
import Link from 'components/Link/Link';
import { PriceButtonColors } from 'components/PriceButton/PriceButton';
import Responsive from 'components/Responsive/Responsive';
import TableViewCell from 'components/TableViewCell/TableViewCell';
import UrgencyBadge, { Badge } from 'components/UrgencyBadge/UrgencyBadge';
import { FullEvent } from 'models';
import { DATE_FORMAT_M_D } from 'utils/datetime';
import { formatPriceWithComma } from 'utils/number';
import { addQuery, removeQueryParams } from 'utils/url';

import { EVENT_CELL_TYPES } from './constants';
import { getDateFormat, getTitle } from './utils';

import styles from './EventCell.module.scss';

interface EventCellProps {
  fullEvent: FullEvent;
  type?: ValueOf<typeof EVENT_CELL_TYPES>;
  onClick?: VoidFunction;
  clickTracker?: ClickTracker | object;
  hideLocation?: boolean;
  isActive?: boolean;
  isSmall?: boolean;
  isSearchBar?: boolean;
  badgeProps?: Badge | null;
  priceButtonColors?: PriceButtonColors;
  showInlinePricing?: boolean;
  showTooltip?: boolean;
  isReplace?: boolean;
}

function EventCell({
  fullEvent,
  type = EVENT_CELL_TYPES.DEFAULT,
  onClick,
  clickTracker,
  hideLocation = false,
  isSmall = false,
  isSearchBar = false,
  badgeProps,
  isActive = false,
  priceButtonColors,
  showInlinePricing = false,
  showTooltip = false,
  isReplace = false,
}: EventCellProps) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const title = getTitle(fullEvent, type);
  const dateFormat = isSearchBar ? DATE_FORMAT_M_D : getDateFormat(fullEvent);
  const { dateLabel, dayLabel } = fullEvent.getDateLabels(dateFormat);
  const price = fullEvent.getPrice();
  const formattedPrice = price ? `$${formatPriceWithComma(price)}+` : '';

  const getEventUrl = () => {
    const queryAlgoliaFields = {
      queryId: fullEvent.algoliaFields.queryId || searchParams.get('queryId'),
      resultPosition:
        fullEvent.algoliaFields.resultPosition ||
        searchParams.get('resultPosition'),
      searchIndex:
        fullEvent.algoliaFields.searchIndex || searchParams.get('searchIndex'),
      searchSessionId:
        fullEvent.algoliaFields.searchSessionId ||
        searchParams.get('searchSessionId'),
    };

    // Remove unnecessary query params
    const search = removeQueryParams(location.search, ['page']);

    const eventUrl = addQuery(fullEvent.getPath(), search, queryAlgoliaFields);

    return eventUrl;
  };

  const getBulletListItems = () => {
    const bulletListItems: React.ReactNode[] = [];
    const bannerEmoji = fullEvent.event.banner.emoji;

    bulletListItems.push(fullEvent.getTime());

    if (showInlinePricing && formattedPrice !== '') {
      bulletListItems.push(
        <span className={styles['inline-pricing']}>{formattedPrice}</span>
      );
    }

    if (bannerEmoji) {
      bulletListItems.push(
        <Responsive
          xs={<Emoji emoji={bannerEmoji} />}
          md={
            <>
              <Emoji emoji={bannerEmoji} />
              {!isSearchBar && (
                <span className={styles['banner-headline']}>
                  {fullEvent.event.banner.headline}
                </span>
              )}
            </>
          }
        />
      );
    }

    if (!hideLocation) {
      if (fullEvent.venue.name) {
        bulletListItems.push(fullEvent.venue.name);
      }

      bulletListItems.push(fullEvent.venue.getLocation());
    }

    return bulletListItems;
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
    if (isReplace) {
      navigate(getEventUrl(), { replace: true });
    }
  };

  return (
    <Link
      replace={isReplace}
      to={getEventUrl()}
      onClick={handleOnClick}
      clickTracker={clickTracker}
      title={showTooltip ? title : undefined}
    >
      <TableViewCell
        leftTitle={dateLabel}
        leftSubtitle={dayLabel}
        title={title}
        subtitle={<BulletList listItems={getBulletListItems()} />}
        price={!showInlinePricing ? formattedPrice : undefined}
        rightColors={priceButtonColors}
        isActive={isActive}
        isSearchBar={isSearchBar}
        isSmall={isSmall}
        badge={badgeProps && <UrgencyBadge badge={badgeProps} />}
      />
    </Link>
  );
}

export default EventCell;
