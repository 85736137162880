import { useEffect, useState } from 'react';
import { Location } from 'react-router-dom';
import { ValueOf } from 'type-fest';

import { Click, ClickTracker, InsuranceRendered } from 'analytics';
import { InsuranceInfoCollection } from 'analytics/events/InsuranceInfoCollection';
import { postInsuranceQuote } from 'services/insurance/insurance.service';
import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import {
  exhaustiveSwitchGuard,
  InsuranceEligibleResult,
  InsuranceInfo,
  InsuranceInput,
  InsuranceQuoteDetails,
  UserModel,
} from 'types';
import { FormSubmitError, HttpError } from 'utils/errors';
import { searchStringToQueryObject } from 'utils/url';

type PostInsuranceQuoteParams = Parameters<typeof postInsuranceQuote>[0];
export type BookingStatus = InsuranceInfo['status'];

type PurchaseType = ValueOf<typeof PURCHASE_TYPE>;
interface UseInsuranceParams extends PostInsuranceQuoteParams {
  isEnabled: boolean;
  updateUser: (user: UserModel) => Promise<void>;
  authUser: UserModel;
  location: Location;
  /**
   * total purchase price before insurance, including promo discounts
   */
  adjustedTotal: number;
  selectedPurchaseType: PurchaseType | undefined;
  analytics: { track: (event: object) => void };
  eventDatetimeUtc: string;
  listingId: string;
  performerId: string;
  enabledInsurancePaymentMethods: Record<
    ValueOf<typeof PURCHASE_TYPE>,
    boolean
  >;
}

interface InsuranceQuote {
  /**
   * raw insurance quote details from XCover
   */
  details: InsuranceQuoteDetails;
  /**
   * total quote price in dollars
   */
  totalPrice: number;
  /**
   * total quote price in cents divided by seat count, rounded up to the next
   * whole cent, then converted to dollars
   */
  unitPrice: number;
  /**
   * whether the quote and user information has been updated
   */
  isUpdated: boolean;
}

/**
 * Parameters for updating the insurance quote and filling in any missing user
 * information that is required for booking insurance.
 */
export interface UpdateQuoteParams {
  zipCode: string;
  firstName: string;
  lastName: string;
}

export interface Insurance {
  isEnabled: boolean;
  isLoadingQuote: boolean;
  quote: InsuranceQuote | null;
  eligibility: InsuranceEligibleResult | null;
  isLoadingEligibility: boolean;
  /**
   * Update the insurance quote with the user's correct zip code and fill in any
   * missing user information required for booking insurance.
   */
  handleDataCollectionFormSubmit: (params: UpdateQuoteParams) => Promise<void>;
  /**
   * Whether the user is opting-in to insurance coverage. If undefined, the user
   * has not made a selection and may move forward with the purchase, implicitly
   * opting out of coverage.
   */
  isSelected: boolean | undefined;
  /**
   * Set whether the user is opting-in to insurance coverage. Once set, there is
   * no way to unset this value - if the user has opted in, they must continue
   * purchase with insurance or explicitly opt out by selecting no coverage.
   */
  setIsSelected: (isSelected: boolean) => void;
  /**
   * Whether the user is booking insurance coverage. This is a combination of
   * the user's selection and if the payment method is valid for purchasing
   * insurance coverage.
   */
  isBookingInsurance: boolean | undefined;
  /**
   * Total purchase price in dollars including insurance, if the user has opted
   * in.
   */
  adjustedTotalWithInsurance: number;
  trackOptInChange: (isSelected: boolean) => void;
  /**
   * return options to add to the purchase request
   */
  getPurchaseOptions: () => InsuranceInput | undefined;
  /**
   * return additional tracking payload items for a purchase button click event
   */
  getPurchaseTrackingPayload: (payment_method: string) => {
    payment_method: string;
    insurance_opt_in: boolean;
    final_quote: number | undefined;
    quantity: number;
    post_fee_price: number;
    insurance_eligible: boolean;
  };
  /**
   * return additional tracking payload items for post-purchase tracking
   */
  getPostPurchaseTrackingPayload: () => {
    insurance_opt_in: boolean;
    insurance_eligible: boolean;
  };
}

/**
 * Divides total quote price by ticket quantity, round up to the next whole
 * cent, and return price per ticket in dollars.
 */
export function getInsuranceUnitPrice(
  details: InsuranceQuoteDetails,
  quantity: number
) {
  return Math.ceil(details.total_price / quantity) / 100;
}

/**
 * Returns the total quote price in dollars.
 */
export function getInsuranceTotalPrice(
  details: InsuranceQuoteDetails | InsuranceInfo
) {
  return details.total_price / 100;
}

/**
 * Returns whether the user has opted in to insurance coverage based on the
 * booking status in the completed purchase.
 *
 * - No status indicates the purchase was made without insurance, implicitly
 *   opting out.
 * - Statuses "failed" and "system_error" indicate the user did opt-in to
 *   insurance coverage but the insurance purchase failed.
 */
export function getIsInsuranceOptInStatus(status: BookingStatus | undefined) {
  const optInStatus: BookingStatus[] = [
    'confirmed',
    'pending_booking',
    'failed',
    'system_error',
  ];

  return !!status && optInStatus.includes(status);
}

/**
 * Return status label and message for insurance coverage in order confirmation
 */
export function getInsuranceStatus(status?: BookingStatus) {
  if (!status) return null;

  switch (status) {
    case 'pending_booking': {
      return {
        label: 'pending' as const,
        message:
          "Your ticket protection order is processing. You'll receive an email once it's complete.",
      };
    }
    case 'system_error':
    case 'failed': {
      return {
        label: 'cancelled' as const,
        message:
          'Your ticket protection order failed to process. For help, email support@xcover.com.',
      };
    }
    case 'confirmed':
    case 'opted_out':
    case 'pending_opt_out': {
      return {
        label: null,
        message: null,
      };
    }
    default: {
      return exhaustiveSwitchGuard(status);
    }
  }
}

/**
 * estimate eligibility when A/B test is not enabled for tracking purposes.
 * Note that the following does not account for location since we won't have it.
 */
const estimateInsuranceEligible = (
  adjustedTotal: number,
  eventDatetimeUtc: string
): boolean => {
  // total price must be at least $50...
  const eligiblePrice = adjustedTotal >= 50;
  // ...and event start time must be at least 24 hours from now
  const TWENTY_FOUR_HOURS_FROM_NOW = Date.now() + 24 * 60 * 60 * 1000;
  const eventStart = new Date(eventDatetimeUtc).getTime();
  const eligibleDate = eventStart > TWENTY_FOUR_HOURS_FROM_NOW;

  return eligiblePrice && eligibleDate;
};

export function useInsurance({
  adjustedTotal,
  eventId,
  quantity,
  authUser,
  isEnabled,
  updateUser,
  location,
  selectedPurchaseType,
  analytics,
  eventDatetimeUtc,
  listingId,
  performerId,
}: UseInsuranceParams): Insurance {
  const [isLoadingQuote, setIsLoadingQuote] = useState(false);
  const [quote, setQuote] = useState<InsuranceQuote | null>(null);

  const [isLoadingEligibility, setIsLoadingEligibility] = useState(true);
  const [eligibility, setEligibility] =
    useState<InsuranceEligibleResult | null>(null);

  const [isSelected, setIsSelected] = useState<Insurance['isSelected']>();

  // Insurance is not supported for purchases using Google Pay or Affirm
  const isBookingInsurance =
    selectedPurchaseType &&
    selectedPurchaseType !== PURCHASE_TYPE.GOOGLE_PAY &&
    selectedPurchaseType !== PURCHASE_TYPE.AFFIRM_PAY &&
    isSelected;

  const query = searchStringToQueryObject(location.search);

  useEffect(() => {
    // reset eligibility and quote if dependencies change
    setIsLoadingEligibility(true);
    setEligibility(null);
    setIsLoadingQuote(true);
    setQuote(null);

    if (!isEnabled) {
      analytics.track(
        new InsuranceRendered({
          quantity,
          post_fee_price: adjustedTotal,
          insurance_eligible: estimateInsuranceEligible(
            adjustedTotal,
            eventDatetimeUtc
          ),
        })
      );
      return;
    }

    const zipCode =
      typeof query.insuranceZip === 'string' ? query.insuranceZip : undefined;

    // initial quote does not have a zip code, will be inferred from IP address lookup
    postInsuranceQuote({
      eventId,
      quantity,
      adjustedTotal,
      authUser: { id: authUser.id, session_token: authUser.session_token },
      zipCode,
    })
      .then((res) => {
        if (res) {
          setEligibility(res.insurance_eligibility_result);

          if (res.details) {
            setQuote({
              details: res.details,
              totalPrice: getInsuranceTotalPrice(res.details),
              unitPrice: getInsuranceUnitPrice(res.details, quantity),
              isUpdated: false,
            });
          }

          analytics.track(
            new InsuranceRendered({
              quantity,
              post_fee_price: adjustedTotal,
              insurance_eligible: res.insurance_eligibility_result.eligible,
              insurance_initial_quote:
                res.details && getInsuranceTotalPrice(res.details),
            })
          );
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingQuote(false);
        setIsLoadingEligibility(false);
      });
  }, [
    isEnabled,
    eventId,
    quantity,
    adjustedTotal,
    // only include required request parameters from authUser to avoid
    // re-requesting after user updates their contact information
    authUser.id,
    authUser.session_token,
    query.insuranceZip,
    analytics,
    eventDatetimeUtc,
  ]);

  /**
   * Update the insurance quote with the user's correct zip code.
   */
  const handleDataCollectionFormSubmit = async ({
    zipCode,
    firstName,
    lastName,
  }: UpdateQuoteParams) => {
    if (!quote) {
      throw new Error('Quote must be created before it can be updated');
    }

    setIsLoadingQuote(true);

    const errors: { [field: string]: string } = {};

    await Promise.all([
      // update the insurance quote with the user's zip code
      postInsuranceQuote({
        eventId,
        quantity,
        adjustedTotal,
        authUser,
        zipCode,
      })
        .then((res) => {
          // eligibility can change if a user updates their zip code to an
          // ineligible location
          if (!res?.insurance_eligibility_result.eligible) {
            errors.zipCode =
              res?.insurance_eligibility_result.reason ||
              'This location is not eligible for insurance coverage';
          }

          if (res?.details) {
            const totalPrice = getInsuranceTotalPrice(res.details);

            setQuote({
              isUpdated: true,
              unitPrice: getInsuranceUnitPrice(res.details, quantity),
              details: res.details,
              totalPrice,
            });

            analytics.track(
              new InsuranceInfoCollection({
                final_quote: totalPrice,
                quantity,
                post_fee_price: adjustedTotal,
              })
            );
          }
        })
        .catch((error) => {
          // the post insurance quote endpoint will return an error message in
          // the body if the zip code is invalid or the location is not found
          if (
            error instanceof HttpError &&
            typeof error.body?.error === 'string'
          ) {
            errors.zipCode = error.body.error;
          } else {
            errors.zipCode = error.message;
          }
        })
        .finally(() => setIsLoadingQuote(false)),
      // update the user's contact information with fields required for
      // booking insurance
      updateUser({
        ...authUser,
        first_name: firstName,
        last_name: lastName,
      }).catch(() => {
        errors.firstName =
          'There was an error updating your contact information';
      }),
    ]);

    if (Object.keys(errors).length > 0) {
      throw new FormSubmitError(errors);
    }
  };

  const trackOptInChange = (isSelected: boolean) => {
    const tracker = new ClickTracker()
      .sourcePageType(Click.SOURCE_PAGE_TYPES.CHECKOUT())
      .interaction(
        Click.INTERACTIONS.INSURANCE_OPTIONS_SELECTION({
          isSelected,
          eventId,
          listingId,
          performerId,
        })
      )
      .payload({
        quantity,
        post_fee_price: adjustedTotal,
      })
      .json();
    analytics.track(new Click(tracker));
  };

  const adjustedTotalWithInsurance =
    isBookingInsurance && quote
      ? adjustedTotal + quote.totalPrice
      : adjustedTotal;

  const getPurchaseOptions = (): InsuranceInput | undefined => {
    if (!isEnabled || !quote?.details) {
      return undefined;
    }

    return {
      quote_id: quote.details.quote_id,
      quote_package_id: quote.details.package_id,
      postal_code: quote.details.postal_code,
      is_booking: isBookingInsurance,
    };
  };

  const getPurchaseTrackingPayload = (payment_method: string) => ({
    payment_method,
    insurance_opt_in: !!isBookingInsurance,
    final_quote: quote?.totalPrice,
    quantity,
    post_fee_price: adjustedTotal,
    insurance_eligible:
      eligibility?.eligible ??
      estimateInsuranceEligible(adjustedTotal, eventDatetimeUtc),
  });

  const getPostPurchaseTrackingPayload = () => ({
    insurance_opt_in: !!isBookingInsurance,
    insurance_eligible:
      eligibility?.eligible ??
      estimateInsuranceEligible(adjustedTotal, eventDatetimeUtc),
  });

  return {
    isEnabled,
    quote,
    handleDataCollectionFormSubmit,
    eligibility,
    isLoadingEligibility,
    isLoadingQuote,
    isSelected,
    setIsSelected,
    isBookingInsurance,
    adjustedTotalWithInsurance,
    trackOptInChange,
    getPurchaseOptions,
    getPurchaseTrackingPayload,
    getPostPurchaseTrackingPayload,
  };
}
