import type { ClientOptions } from '@sentry/core';

import { ignoreErrors } from 'server/constants';

import { NetworkError } from './errors';

export const beforeSend: NonNullable<ClientOptions['beforeSend']> = (
  event,
  hint
) => {
  const error = hint.originalException;

  if (error instanceof NetworkError) {
    // network errors are thrown by HttpClient when fetch fails to receive a
    // response from the server.
    return null;
  } else if (
    typeof error === 'string' &&
    ignoreErrors.some((regex) => error.match(regex))
  ) {
    return null;
  } else if (
    error instanceof Error &&
    error.message &&
    ignoreErrors.some((regex) => error.message.match(regex))
  ) {
    return null;
  }

  return event;
};
