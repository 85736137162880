import React from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import CloseLineIcon from 'icons/CloseLineIcon';
import colors from 'styles/colors.constants';

import styles from './GalleryViewCard.module.scss';

interface GalleryViewCardProps {
  children: React.ReactNode;
  onClose: VoidFunction;
  isVisible: boolean;
  isAnimating: boolean;
}

function GalleryViewCard({
  children,
  onClose,
  isVisible,
  isAnimating,
}: GalleryViewCardProps) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={classNames(styles.container, {
            [styles['is-animating']]: isAnimating,
          })}
          initial={{ opacity: 0, y: '100%' }}
          animate={{ opacity: 1, y: '0%' }}
          exit={{ opacity: 0, y: '100%' }}
        >
          <button className={styles['close-button']} onClick={onClose}>
            <span className={styles['close-button-inner']}>
              <CloseLineIcon fill={colors.black} width="16" height="16" />
            </span>
          </button>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default GalleryViewCard;
