import React from 'react';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';

import { Disclosure, Listing } from 'models';
import ListingFocusedCard from 'pages/Event/components/ListingFocusedCard';
import { hasHighlightedDisclosure } from 'store/modules/data/Listings/utils';

import styles from './GalleryViewTooltip.module.scss';

interface GalleryViewTooltipProps {
  children: React.ReactNode;
  listing: Listing;
  isAllInPrice: boolean;
  disableTooltip?: boolean;
  allDisclosures: Record<string, Disclosure>;
  isMLBEvent: boolean;
  onHover: () => void;
  isBackgroundPin?: boolean;
}

const GalleryViewTooltip = ({
  children,
  listing,
  isAllInPrice,
  disableTooltip = true,
  allDisclosures,
  isMLBEvent,
  onHover,
  isBackgroundPin = false,
}: GalleryViewTooltipProps) => {
  if (disableTooltip) {
    return <>{children}</>;
  }

  const withHighlighteDisclosures: boolean = hasHighlightedDisclosure(
    listing.disclosures,
    allDisclosures
  );

  const handleAfterVisibleChange = (visible: boolean) => {
    if (visible) {
      onHover();
    }
  };

  const tooltipPlacement = isBackgroundPin ? 'topRight' : 'topLeft';

  return (
    <Tooltip
      zIndex={10000}
      showArrow={false}
      placement={tooltipPlacement}
      overlayClassName="gallery-view-tooltip"
      trigger={['hover']}
      mouseLeaveDelay={0}
      overlayStyle={{
        padding: '4px 0px',
        opacity: 1,
      }}
      overlayInnerStyle={{ padding: 0 }}
      align={{ overflow: { adjustX: true, adjustY: true } }}
      mouseEnterDelay={0.2}
      afterVisibleChange={handleAfterVisibleChange}
      overlay={
        <div
          className={classNames(styles['tooltip-container'], {
            [styles['highlighted-disclosures']]: withHighlighteDisclosures,
          })}
        >
          <ListingFocusedCard
            bordered
            listing={listing}
            allDisclosures={allDisclosures}
            isAllInPrice={isAllInPrice}
            isMLBEvent={isMLBEvent}
          />
        </div>
      }
    >
      <div
        className={classNames(styles['marker-container'], {
          [styles['background-pin']]: isBackgroundPin,
        })}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default GalleryViewTooltip;
