import React from 'react';

import styles from './Alert.module.scss';

interface Props {
  message: string;
  icon: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
}

export default function Alert({
  message,
  icon,
  backgroundColor,
  borderColor,
  textColor,
}: Props) {
  return (
    <div
      className={styles['alert']}
      style={{
        '--background-color': backgroundColor,
        '--border-color': borderColor,
        '--text-color': textColor,
      }}
    >
      <div className={styles['body']}>
        {message}
        {icon}
      </div>
    </div>
  );
}
