import { useMemo } from 'react';

import { Listing } from 'models';

interface UseListingPriceParams {
  listing: Listing;
  isAllInPriceActive: boolean;
  isListingDetails: boolean;
  priceWithPromoApplied: number;
  isPurchaseListing: boolean;
}

const useListingPrice = ({
  listing,
  isAllInPriceActive,
  isListingDetails,
  priceWithPromoApplied,
  isPurchaseListing,
}: UseListingPriceParams): number => {
  const price = useMemo(() => {
    let listingPrice: number = isAllInPriceActive
      ? listing.preTaxTotal
      : listing.prefeePrice;

    /**
     * Final Promo Price (from BE)
     * (Seat Fees * Seat Count) - (Sales Tax * Seat Count)
     */

    if (isListingDetails && priceWithPromoApplied > 0 && isPurchaseListing) {
      const totalSeatFee = listing.fees * listing.quantity;
      const totalSalesTax = listing.salesTax * listing.quantity;
      const totalTaxAndFees = totalSeatFee + totalSalesTax;
      let adjustedTotal = priceWithPromoApplied;

      if (!isAllInPriceActive) {
        adjustedTotal -= totalTaxAndFees;
      }

      if (adjustedTotal > 0 && adjustedTotal !== listingPrice) {
        listingPrice = Math.ceil(adjustedTotal / listing.quantity);
      }
    }
    return listingPrice;
  }, [
    isAllInPriceActive,
    isListingDetails,
    listing,
    priceWithPromoApplied,
    isPurchaseListing,
  ]);

  return price;
};

export default useListingPrice;
