import React from 'react';
import {
  StatsigProvider,
  StatsigUser,
  useClientBootstrapInit,
} from '@statsig/react-bindings';
import Cookies from 'js-cookie';
import { ClientInitializeResponse } from 'statsig-node/dist/Evaluator';

import commonConfig from '../../config/common.config';
import config from '../../config/variants';

type Props = Readonly<{
  children: React.ReactNode;
  environment?: string;
  initialValues?: {
    statsig?: ClientInitializeResponse;
  } | null;
  version?: string;
}>;

function debug(...values: unknown[]): void {
  if (config.debug) {
    console.info(...values);
  }
}

function Statsig({
  children,
  environment,
  initialValues,
  token,
  version,
}: Readonly<{
  children: Props['children'];
  initialValues?: ClientInitializeResponse | null;
  token: string;
  userId?: string;
  environment?: Props['environment'];
  version?: Props['version'];
}>): React.JSX.Element {
  const options = {
    environment: {
      tier: environment,
    },
  };

  const user: StatsigUser = initialValues
    ? initialValues.user
    : {
        appVersion: version,
        custom: { platform: 'web' },
        userID: Cookies.get(commonConfig.cookiesConfig.WEB_USER_ID.name),
      };

  const client = useClientBootstrapInit(
    token,
    user,
    JSON.stringify(initialValues),
    options
  );

  if (initialValues) {
    debug('🥾 Bootstrapping Statsig from server values.');
    debug('👤 Client-side bootstrapped user', initialValues.user);

    return <StatsigProvider client={client}>{children}</StatsigProvider>;
  }

  debug('🚧 No initial values, initializing Statsig on client.');

  const sessionId = Cookies.get(commonConfig.cookiesConfig.WEB_SESSION_ID.name);

  if (sessionId) {
    user.customIDs = { sessionId };
  }

  if (typeof window !== 'undefined') {
    user.userAgent = window.navigator.userAgent;
  }

  debug('👤 Client-side user', user);

  return (
    <StatsigProvider sdkKey={token} user={user} options={options}>
      {children}
    </StatsigProvider>
  );
}

export default function VariantProvider({
  environment,
  children,
  initialValues,
  version,
}: Props): React.JSX.Element {
  // Disable eslint rule for this line because the config is hardcoded without types
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const token = config[config.provider]?.credentials.client_token;

  // Disable eslint rule for this line because the config is hardcoded without types
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (token === undefined) {
    throw new Error(`No ${config.provider} client token defined.`);
  }

  switch (config.provider) {
    // Disable eslint rule for this line because there's only one provider at the moment
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    case 'statsig':
      const initialState = initialValues?.[config.provider];

      return (
        <Statsig
          environment={environment}
          token={token}
          initialValues={initialState}
          version={version}
        >
          {children}
        </Statsig>
      );

    default:
      return <>{children}</>;
  }
}
