import React, { useEffect } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';

import { usePurchaseFlow } from 'routes/PurchaseFlowContext';

import config from '../../config/variants';
import { useVariantService } from '../../services/variants';
import PurchaseV3 from '../PurchaseV3/PurchaseV3';

import { PURCHASE_STEPS } from './constants';
import {
  PurchaseSecure,
  PurchaseUserCollectAddress,
  PurchaseUserPhone,
  PurchaseUserTextMessageCollection,
  PurchaseUserTransfer,
} from './steps';

function Purchase() {
  const {
    purchaseStep,
    purchaseType,
    handleSubmitTransferEmail,
    handleSubmitTransferPhone,
    handleSubmitUserPhone,
    handleSubmitUserDeliveryAddress,
    handleSubmitVerifyBillingAddress,
    isMLBInfoCollection,
  } = usePurchaseFlow();

  const location = useLocation();
  const navigate = useNavigate();
  const { listing, event } = useOutletContext();
  const variantService = useVariantService();

  if (
    variantService.hasExperimentValue(
      config.experiments.checkoutV3,
      'theme',
      'dark',
      'light'
    )
  ) {
    return <PurchaseV3 />;
  }

  const listingPath = listing.getPath(event);

  useEffect(() => {
    if (!purchaseStep) {
      navigate(
        {
          pathname: listingPath + '/checkout',
          search: location.search,
        },
        { replace: true }
      );
    }
  }, [purchaseStep, location.search, listingPath, navigate]);

  switch (purchaseStep) {
    // pre-purchase:
    case PURCHASE_STEPS.USER_TEXT: {
      return (
        <PurchaseUserTextMessageCollection
          onSubmit={handleSubmitTransferPhone}
          listing={listing}
        />
      );
    }
    case PURCHASE_STEPS.USER_TRANSFER: {
      return (
        <PurchaseUserTransfer
          onSubmit={handleSubmitTransferEmail}
          isMLBInfoCollection={isMLBInfoCollection}
          listing={listing}
        />
      );
    }
    case PURCHASE_STEPS.SECURE: {
      return <PurchaseSecure listing={listing} />;
    }

    // post-purchase:
    case PURCHASE_STEPS.USER_PHONE: {
      return (
        <PurchaseUserPhone onSubmit={handleSubmitUserPhone} listing={listing} />
      );
    }
    case PURCHASE_STEPS.USER_VERIFY: {
      return (
        <PurchaseUserCollectAddress
          onSubmit={handleSubmitVerifyBillingAddress}
          purchaseType={purchaseType}
          listing={listing}
        />
      );
    }
    case PURCHASE_STEPS.USER_ADDRESS: {
      return (
        <PurchaseUserCollectAddress
          delivery
          onSubmit={handleSubmitUserDeliveryAddress}
          purchaseType={purchaseType}
          listing={listing}
        />
      );
    }

    // should not be reachable
    default: {
      return null;
    }
  }
}

export default Purchase;
