import React from 'react';
import classNames from 'classnames';

import { device, useMediaQuery } from 'hooks';
import { Listing } from 'models';
import { isObjectEmpty } from 'utils/objects';

import { usePinPosition } from '../hooks';

import styles from './PinContainer.module.scss';

interface PinContainerProps {
  children: React.ReactNode;
  mapImage: { width: number; height: number };
  listing: Listing;
  customStyles?: React.CSSProperties;
  isLargePin: boolean;
  customSize?: number;
  pinContainerClassNames?: string;
  scale?: number;
  isBackgroundPinContainer?: boolean;
  zIndex: number;
}

const PIN_HEIGHT_LARGE = 54;
const PIN_HEIGHT_SMALL = 38;
const BACKGROUND_PIN_HEIGHT = 10;
const BACKGROUND_PIN_HEIGHT_MOBILE = 8;

/**
 * Note:
 * PinContainer should be used on all pins to
 * encapsulate zoom logic and to prevent/reduce unwanted re-renders.
 * PinContainer must be placed inside TransformWrapper Map (view test file).
 */
const PinContainer = ({
  children,
  mapImage,
  listing,
  customStyles = {},
  isLargePin,
  customSize,
  pinContainerClassNames = '',
  scale = 1,
  isBackgroundPinContainer = false,
  zIndex,
}: PinContainerProps) => {
  const isMobile = useMediaQuery(device.down.md);
  const { widthRatio, heightRatio, inverseScale } = usePinPosition(
    mapImage.width,
    mapImage.height,
    scale
  );

  if (isObjectEmpty(listing) || mapImage.width === -1) {
    return;
  }

  const coord = {
    top: listing.position.y * heightRatio,
    left: listing.position.x * widthRatio,
  };

  const baseStyles: React.CSSProperties = {
    ...coord,
    '--listing-z-index': zIndex,
  };

  if (customSize) {
    const adjustedHeight = customSize * inverseScale;
    baseStyles.marginTop = -adjustedHeight;
    baseStyles.transform = `translateX(-6px) scale(${inverseScale})`;
  } else {
    const inverseTranslateX = (inverseScale / 2) * 100;
    const normalPinHeight = isLargePin ? PIN_HEIGHT_LARGE : PIN_HEIGHT_SMALL;
    const backgroundPinHeight = isMobile
      ? BACKGROUND_PIN_HEIGHT_MOBILE
      : BACKGROUND_PIN_HEIGHT;
    const pinHeight = isBackgroundPinContainer
      ? backgroundPinHeight
      : normalPinHeight;
    const adjustedHeight = pinHeight * inverseScale;
    baseStyles.transform = `translateX(-${inverseTranslateX}%) scale(${inverseScale})`;
    baseStyles.marginTop = -adjustedHeight;
  }

  return (
    <div
      id={listing.id}
      data-testid="pin-container"
      className={classNames(styles['pin-container'], pinContainerClassNames)}
      style={{
        ...baseStyles,
        ...customStyles,
      }}
    >
      {children}
    </div>
  );
};

export default PinContainer;
