export interface SearchParams {
  q: string;
  lat: number;
  lon: number;
}

export interface SearchRequest {
  searchParams: SearchParams;
  headers?: { user_id: string };
}

export const paramsToString = (params: SearchParams) =>
  Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
