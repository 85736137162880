import { combineReducers } from '@reduxjs/toolkit';

import app from './app/app';
import uiReducer from './app/app.ui';
import blog from './blog/blogReducer';
import content from './content/contentReducer';
import data from './data/dataReducer';
import dataFilters from './data/filtersReducer';
import genrePerformers from './genrePerformers/genrePerformers';
import listings from './listingsV3/reducer';
import localeConfigs from './localeConfigs/reducer';
import modals from './modals/modals';
import performers from './performers/reducer';
import resources from './resources/resource';
import user from './user/user';
import userPreference from './userPreference/userPreference';
import userPurchases from './userPurchases/userPurchases';
import venues from './venues/reducer';

const rootReducer = combineReducers({
  user,
  userPurchases,
  app,
  resources,
  userPreference,
  uiReducer,
  listings,
  localeConfigs,
  modals,
  genrePerformers,
  data,
  dataFilters,
  content,
  blog,
  performers,
  venues,
});

export default rootReducer;
