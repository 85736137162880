import React from 'react';
import classNames from 'classnames';

import { InfoIcon } from 'icons';
import { Listing } from 'models';
import colorsConstants from 'styles/colors.constants';
import { formatPriceWithComma, isInteger, round } from 'utils/number';

import styles from './PriceBlock.module.scss';

type Props = {
  onPriceBreakdownInfoClick: () => void;
  showFaceValuePricing: boolean;
  showAllInPricing: boolean;
  priceWithPromoApplied: number;
  currencyPrefix: string;
  listing: Listing;
};

export default function PriceBlock({
  onPriceBreakdownInfoClick,
  showFaceValuePricing,
  showAllInPricing,
  priceWithPromoApplied,
  currencyPrefix,
  listing,
}: Props) {
  if (listing.quantity < 1) {
    return null;
  }
  const price = showAllInPricing ? listing.preTaxTotal : listing.prefeePrice;
  /**
   * If the user has at least one promo active in their account, calculate the
   * price to show on the listing details page here. The math for calculating
   * this price is as follows:
   *
   * Final Promo Price (from BE) - (Seat Fees * Seat Count) - (Sales Tax * Seat Count)
   */
  const totalTaxAndFees = listing.fees * listing.quantity;
  const adjustedTotal =
    priceWithPromoApplied - (showAllInPricing ? 0 : totalTaxAndFees);

  const isPromoApplied = adjustedTotal > 0 && adjustedTotal !== price;
  const isDiscounted = listing.isDiscounted();
  const savings = listing.getSavingsAmount() || 0;

  const formattedPrice = round(
    isPromoApplied ? Math.ceil(adjustedTotal / listing.quantity) : price
  );
  const hasFixedDigits = !isInteger(formattedPrice);

  const renderDiscountRow = () => {
    if (isPromoApplied) {
      return <span className={styles['discount-row']}>Promo Applied</span>;
    }

    if (isDiscounted) {
      return (
        <span className={styles['discount-row']}>
          you save ${formatPriceWithComma(savings * listing.quantity)}
        </span>
      );
    }

    return null;
  };

  return (
    <div className={styles['price-block']}>
      {renderDiscountRow()}
      <div className={styles['price-row']}>
        <span
          className={classNames(styles.price, {
            [styles['promo-applied']]: isPromoApplied,
          })}
        >
          {currencyPrefix}
          {formatPriceWithComma(formattedPrice, hasFixedDigits)}
        </span>
        {isDiscounted && (
          <span className={styles['price-before-promo']}>
            {currencyPrefix}
            {formatPriceWithComma(price + savings)}
          </span>
        )}
        <span className={styles['each-text']}>each</span>
      </div>
      {(showFaceValuePricing || showAllInPricing) && (
        <button
          className={styles['info-button']}
          onClick={onPriceBreakdownInfoClick}
          aria-label="Show total price breakdown"
        >
          <span className={styles['info-text']}>Includes Fees</span>
          <InfoIcon fill={colorsConstants.gray300} height={16} width={16} />
        </button>
      )}
    </div>
  );
}
