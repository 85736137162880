import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';
import { GetLocaleConfigsRequestParams, GetLocaleConfigsResponse } from 'types';

const httpClient = new HttpClient(config.MOBILE_API_URL);

export const getLocaleConfigs = (
  params: GetLocaleConfigsRequestParams
): Promise<GetLocaleConfigsResponse | null> => {
  const searchParams = new URLSearchParams();

  let paramKey: keyof typeof params;
  for (paramKey in params) {
    const value = params[paramKey];
    if (value !== undefined) {
      searchParams.set(paramKey, value.toString());
    }
  }

  return httpClient.request<GetLocaleConfigsResponse>({
    path: '/v1/locale-config',
    method: 'GET',
    searchParams,
  });
};
