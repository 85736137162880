import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { ApplePaySession } from 'braintree-web';
import { HostedFieldsHostedFieldsFieldName } from 'braintree-web/hosted-fields';
import { useGooglePay } from 'contexts/GooglePayContext';
import { useInsurance } from 'contexts/InsuranceContext';
import { useReCaptcha } from 'contexts/ReCaptchaContext';
import _merge from 'lodash/merge';
import pick from 'lodash/pick';
import { ValueOf } from 'type-fest';
import Button from 'ui/Button/Button';
import Dialog from 'ui/Dialog';
import CheckoutContainer from 'ui/Layout/CheckoutContainer/CheckoutContainer';

import {
  Click,
  ClickTracker,
  mapListingTrackingData,
  PAYLOAD,
  PAYMENT_METHOD_NAMES,
  useAnalyticsContext,
  View,
} from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import { SuccessfulAddCard } from 'analytics/events/SuccessfulAddCard';
import { getAddCardTitle } from 'components/Modals/AddCardModal/AddCardModal';
import EditCardModal from 'components/Modals/EditCardModal/EditCardModal';
import { CREDIT_CARD_ERROR_NOTIFICATION } from 'components/Notifications/constants';
import { showNotification } from 'components/Notifications/Notifications';
import { ACTIONS as T_ACTIONS } from 'components/Trackable/TrackingHelper';
import { BraintreeBillingAddress, BraintreeTokenize } from 'helpers/Braintree';
import { RE_CAPTCHA_ACTIONS } from 'helpers/Google/GoogleReCaptcha';
import { CheckCircleFilledIcon, Paypal } from 'icons';
import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import { getEventPageTitle } from 'modules/pageTitles';
import InsuranceOptions from 'pages/Checkout/components/InsuranceOptions/InsuranceOptions';
import PaymentMethods from 'pages/Checkout/components/PaymentMethods';
import { usePurchaseFlow } from 'routes/PurchaseFlowContext';
import { DataLoader } from 'routes/routes.utils';
import { useAppDispatch, useAppSelector } from 'store';
import { CARD_TYPES } from 'store/datatypes/PAYMENT_TYPES';
import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import { extendedPurchaseSelector } from 'store/modules/purchase/purchase.selectors';
import {
  addUserCreditCard,
  deleteUserCreditCard,
} from 'store/modules/purchase/purchaseFlow';
import { fetchDisclosures } from 'store/modules/resources/resource.actions';
import { updateUser } from 'store/modules/user/typedActions';
import {
  selectUserDetails,
  userPromosForListingSavingsSelector,
} from 'store/modules/user/user.selectors';
import { getExternalAccountType } from 'store/modules/user/utils';
import { updateUserDefaultPayment } from 'store/modules/userPurchases/actions';
import { processAffirmPayVcn } from 'store/modules/userPurchases/affirmPay/affirmPay';
import {
  fetchUserCards,
  fetchUserDeviceVerified,
  verifyCVV,
} from 'store/modules/userPurchases/creditCard/creditCard';
import { userPurchaseCreditCard } from 'store/modules/userPurchases/creditCard/creditCard.selectors';
import {
  defaultCardTokenSelector,
  selectApplePay,
} from 'store/modules/userPurchases/userPurchases.selectors';
import { CreditCard, Payment } from 'types';
import { isMLBEvent } from 'utils/mlb';

import { formatPriceWithComma } from '../../utils/number';

import {
  AffirmCheckoutForm,
  NewCardForm,
  VerifyCvvForm,
} from './components/Forms';
import MLBOptIn from './components/MLBOptIn/MLBOptIn';
import {
  AffirmPurchaseButton,
  ApplePayPurchaseButton,
  CreateApplePaySession,
  GooglePayPurchaseButton,
  PaymentSuccessData,
  PayPalPurchaseButton,
} from './components/PurchaseButtons';

import styles from './CheckoutV3.module.scss';

type Analytics = {
  track: (arg: unknown) => void;
};

const radioInputClassList = {
  component: styles['radio-card'],
  ...pick(styles, [
    'checkmark',
    'content',
    'expanded-content-drawer',
    'expanded-content-overflow',
    'expanded-content',
    'header',
    'icon',
    'input',
    'label',
    'subtitle',
    'title-block',
    'title',
  ]),
};

function CheckoutV3() {
  const { listing, event } = useOutletContext<{
    listing: Listing;
    event: FullEvent;
  }>();
  const dispatch = useAppDispatch();
  const reCaptcha = useReCaptcha();
  const googlePayContext = useGooglePay();
  const purchaseFlow = usePurchaseFlow();
  const navigate = useNavigate();
  const location = useLocation();
  const insurance = useInsurance();
  const analytics: Analytics = useAnalyticsContext();
  const clickContext = useClickContext();

  const user = useAppSelector(selectUserDetails);
  const listingPromoSavings = useAppSelector(
    userPromosForListingSavingsSelector
  );
  const creditCard = useAppSelector(userPurchaseCreditCard);
  const defaultCardToken = useAppSelector(defaultCardTokenSelector);
  const extendedPurchase = useAppSelector((state) =>
    extendedPurchaseSelector(state, {
      listing,
    })
  );
  const applePay = useAppSelector(selectApplePay);

  const [selectedCardToken, setSelectedCardToken] =
    useState<CreditCard['token']>(defaultCardToken);
  const [selectedPurchaseType, setSelectedPurchaseType] =
    useState<ValueOf<typeof PURCHASE_TYPE>>();
  const [isFetchingCards, setIsFetchingCards] = useState(false);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMLBMarketingOptedIn, setIsMLBMarketingOptedIn] = useState(false);
  const [isEditCardModalOpen, setIsEditCardModalOpen] = useState(false);
  const [isVerifyCVVModalOpen, setIsVerifyCVVModalOpen] = useState(false);
  const [isInvalidCVVModalOpen, setIsInvalidCVVModalOpen] = useState(false);
  const [invalidCVVAttempts, setInvalidCVVAttempts] = useState(0);
  const [isAffirmFormModalOpen, setIsAffirmFormModalOpen] = useState(false);

  const adjustedTotal = extendedPurchase.totalPrice - listingPromoSavings;
  const pageTitle = getEventPageTitle(event);
  const externalAccount = getExternalAccountType(listing.ticketType);
  const purchaseTypesExcludedFromInsurance: Array<
    ValueOf<typeof PURCHASE_TYPE>
  > = [PURCHASE_TYPE.GOOGLE_PAY, PURCHASE_TYPE.AFFIRM_PAY];

  const shouldShowMLBOptInSection = isMLBEvent(event) && !!externalAccount;
  const shouldShowInsurance =
    insurance.isEnabled &&
    (!selectedPurchaseType ||
      !purchaseTypesExcludedFromInsurance.includes(selectedPurchaseType));

  const trackingPayload = {
    ...mapListingTrackingData(listing),
    [PAYLOAD.MLB_MARKETING_OPT_IN_ELIGIBLE]: shouldShowMLBOptInSection,
    /**
     * quantity and post_fee_price for xCover insurance
     */
    [PAYLOAD.QUANTITY]: listing.quantity,
    [PAYLOAD.POST_FEE_PRICE]: adjustedTotal,
  };

  const insuranceDisclaimer =
    "By clicking, I agree to all plan terms. Plan sold by Cover Genius Insurance Services, LLC via our platform XCover.\
     Insurance by US Fire and non-insurance services by On Call Int'l. Limitations and exclusions apply. Powered by XCover.\
     *Plan cost is subject to change depending on your state of residency.";

  useEffect(() => {
    if (!shouldShowInsurance) {
      insurance.setIsSelected(false);
    }
  }, [shouldShowInsurance]);

  useEffect(() => {
    const pageViewParams = {
      listing,
      performer: event.getPrimaryPerformer(),
      fullEvent: event,
      payload: trackingPayload,
    };

    fetchCreditCards();
    insurance.setAdjustedTotal(adjustedTotal);
    analytics.track(new View(View.PAGE_TYPES.CHECKOUT_V3(pageViewParams)));
  }, []);

  // Whenever the user's credit cards change, update the selected card token to
  // their default card or first one saved to their account
  useEffect(() => {
    if (selectedCardToken && selectedPurchaseType) {
      return;
    }

    const defaultCard = getDefaultCardToken();

    if (defaultCard) {
      setSelectedCardToken(defaultCard);
      setSelectedPurchaseType(PURCHASE_TYPE.CREDIT_CARD_ON_FILE);

      if (insurance.isEnabled) {
        insurance.setSelectedPurchaseType(PURCHASE_TYPE.CREDIT_CARD_ON_FILE);
      }
    }
  }, [creditCard]);

  // Update insurance's price when a promo code is applied
  useEffect(() => {
    insurance.setAdjustedTotal(
      extendedPurchase.totalPrice - listingPromoSavings
    );
  }, [listingPromoSavings]);

  function getDefaultCardToken(): Required<CreditCard>['token'] | null {
    if (defaultCardToken) {
      return defaultCardToken;
    }

    const card = Object.values(creditCard.cards)[0] as CreditCard | undefined;

    return card?.token ?? null;
  }

  function isPurchaseType(
    purchaseType: string
  ): purchaseType is ValueOf<typeof PURCHASE_TYPE> {
    return Object.values(PURCHASE_TYPE).includes(
      purchaseType as ValueOf<typeof PURCHASE_TYPE>
    );
  }

  function isGooglePayAvailable() {
    if (!googlePayContext?.googlePay) {
      return false;
    }

    return (
      googlePayContext.googlePay.statusChecked &&
      googlePayContext.googlePay.available
    );
  }

  function isApplePayAvailable() {
    if (!applePay) {
      return false;
    }

    return applePay.statusChecked && applePay.available;
  }

  function handleSelectPaymentMethod(paymentMethod: string) {
    const [purchaseType, cardToken] = paymentMethod.split(':');

    setSelectedCardToken(cardToken);
    if (isPurchaseType(purchaseType)) {
      setSelectedPurchaseType(purchaseType);
      insurance.setSelectedPurchaseType(purchaseType);
    }
  }

  function initiateCreditCardFlow(token: string) {
    handlePurchaseFlow(PURCHASE_TYPE.CREDIT_CARD_ON_FILE, () => ({
      paymentOptions: {
        token,
      },
      insuranceOptions: insurance.getPurchaseOptions(),
      insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
    }));
  }

  function handlePurchaseFlow(
    purchaseType: ValueOf<typeof PURCHASE_TYPE>,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    resolvePurchaseOptions: Function
  ) {
    if (selectedCardToken && defaultCardToken !== selectedCardToken) {
      dispatch(updateUserDefaultPayment(selectedCardToken));
    }

    purchaseFlow.initPurchaseFlow(
      purchaseType,
      resolvePurchaseOptions,
      isMLBMarketingOptedIn
    );
  }

  async function fetchCreditCards(options = {}) {
    setIsFetchingCards(true);

    try {
      await dispatch(fetchUserCards(user, options));
    } catch (err) {
      console.error(err);
    } finally {
      setIsFetchingCards(false);
    }
  }

  function handleShowAddCardModal() {
    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.ADD_CARD())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.CHECKOUT())
      .targetPageType(Click.TARGET_PAGE_TYPES.CREDIT_CARD_DETAILS());

    analytics.track(new Click(tracker.json()));
    setIsAddCardModalOpen(true);
  }

  async function handleAddCardSubmit(
    braintreeTokenize: BraintreeTokenize,
    billingAddress: BraintreeBillingAddress
  ) {
    if (!reCaptcha.reCaptchaReady) {
      throw new Error('reCaptcha not ready');
    }

    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.ADD_CARD())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.CREDIT_CARD_DETAILS())
      .targetPageType(Click.TARGET_PAGE_TYPES.CHECKOUT());

    analytics.track(new Click(tracker.json()));

    setIsSubmitting(true);

    const [tokenizeData, reCaptchaToken] = await Promise.all([
      braintreeTokenize(billingAddress),
      reCaptcha.executeReCaptcha(RE_CAPTCHA_ACTIONS.ADD_PAYMENT_METHOD),
    ]);

    try {
      const token = await dispatch(
        addUserCreditCard(tokenizeData.nonce, reCaptchaToken)
      );
      setSelectedCardToken(token);
      setSelectedPurchaseType(PURCHASE_TYPE.CREDIT_CARD_ON_FILE);
    } catch {
      showNotification(CREDIT_CARD_ERROR_NOTIFICATION);
    } finally {
      analytics.track(new SuccessfulAddCard({}));

      setIsSubmitting(false);
      setIsAddCardModalOpen(false);
    }
  }

  /**
   * Returns a payment method string that includes purchase type and card token
   * (if it exists). This should be the source of truth for payment method
   * selection and rendering the purchase button. If a card token is not
   * selected, the first card in the user's credit card list will be used
   * as the default payment method.
   *
   * If no cards are available, the default payment method will be undefined.
   */
  function getSelectedPaymentMethod() {
    if (
      selectedPurchaseType === PURCHASE_TYPE.AFFIRM_PAY ||
      selectedPurchaseType === PURCHASE_TYPE.PAYPAL_PAY
    ) {
      return selectedPurchaseType;
    }

    if (selectedPurchaseType === PURCHASE_TYPE.APPLE_PAY) {
      return `${PURCHASE_TYPE.APPLE_PAY}:${CARD_TYPES.APPLEPAY}`;
    }

    if (selectedPurchaseType === PURCHASE_TYPE.GOOGLE_PAY) {
      return `${PURCHASE_TYPE.GOOGLE_PAY}:${CARD_TYPES.GOOGLEPAY}`;
    }

    const cardTokens = Object.keys(creditCard.cards);
    // selected card on file must have a card token that exists in the user's
    // credit card list
    if (
      cardTokens.length > 0 &&
      selectedCardToken &&
      cardTokens.includes(selectedCardToken)
    ) {
      return `${PURCHASE_TYPE.CREDIT_CARD_ON_FILE}:${selectedCardToken}`;
    }
  }

  function isPurchaseButtonDisabled() {
    if (isSubmitting || !selectedPurchaseType) {
      return true;
    }

    if (
      selectedPurchaseType === PURCHASE_TYPE.CREDIT_CARD_ON_FILE &&
      !getPaymentCard()
    ) {
      return true;
    }

    return false;
  }

  function getPaymentCard() {
    if (!selectedCardToken) return null;

    return creditCard.cards[selectedCardToken];
  }

  function handleAffirmCheckout(formData: {
    firstName: string;
    lastName: string;
  }) {
    const { firstName, lastName } = formData;

    dispatch(
      updateUser({
        ...user,
        firstName: firstName,
        lastName: lastName,
      })
    );

    initializeAffirmCheckout({ firstName, lastName });

    setIsAffirmFormModalOpen(false);

    openAffirmCheckout();
  }

  function initializeAffirmCheckout({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) {
    const eventCategory = event.getPrimaryPerformer().category;

    if (
      typeof window !== 'undefined' &&
      window.affirm &&
      typeof window.affirm.checkout === 'function'
    ) {
      const categories = eventCategory ? [[eventCategory]] : undefined;
      window.affirm.checkout({
        // @ts-expect-error - Affirm lacks proper TS support
        merchant: {
          use_vcn: true,
        },
        billing: {
          name: {
            first: firstName,
            last: lastName,
          },
          email: user.email,
        },
        items: [
          {
            display_name: event.getPrimaryPerformer().name,
            sku: listing.id,
            unit_price: listing.price.total,
            qty: listing.quantity,
            categories,
          },
        ],
        currency: 'USD',
        shipping_amount: 0,
        tax_amount: listing.salesTax * 100,
        total: insurance.adjustedTotalWithInsurance * 100,
      });
    }
  }

  function openAffirmCheckout() {
    if (
      typeof window !== 'undefined' &&
      window.affirm &&
      typeof window.affirm.checkout === 'function' &&
      // @ts-expect-error - Affirm lacks proper TS support
      typeof window.affirm.checkout.open_vcn === 'function'
    ) {
      // @ts-expect-error - Affirm lacks proper TS support
      window.affirm.checkout.open_vcn({
        error() {
          console.error('User cancelled the Affirm checkout flow');
        },
        // @ts-expect-error - Affirm lacks proper TS support
        success: (vcn) => {
          processAffirmPayVcn(vcn)
            .then((nonce) => {
              handlePurchaseFlow(PURCHASE_TYPE.AFFIRM_PAY, () => ({
                paymentOptions: {
                  nonce,
                  pay_later_type: 'affirm',
                },
                insuranceOptions: insurance.getPurchaseOptions(),
                insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
              }));
            })
            .catch((err: unknown) => console.error(err));
        },
      });
    }
  }

  function renderApplePayPurchaseButton(
    trackPurchaseButtonClick: (
      modifyTracker?: (tracker: ClickTracker) => void | undefined
    ) => void
  ) {
    const handleClickApplePayButton = (
      createApplePaySession: CreateApplePaySession
    ) => {
      trackPurchaseButtonClick();
      let applePaySession: ApplePaySession | undefined;

      const promise = new Promise((resolve, reject) => {
        applePaySession = createApplePaySession({
          applePayInstance: applePay.applePayInstance,
          amount: insurance.adjustedTotalWithInsurance.toString(),
          onPaymentAuthorized: (payment: Partial<Payment>) => {
            resolve({
              paymentOptions: payment,
              insuranceOptions: insurance.getPurchaseOptions(),
              insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
            });
          },
          onError: (error) => {
            reject(error);
          },
          onCancel: () => {
            const listingPath = listing.getPath(event);
            console.info('Apple Pay session cancelled by user');
            navigate({
              pathname: `${listingPath}/checkout`,
              search: location.search,
            });
            // reject with an error to avoid moving forward in the
            // purchase flow
            reject(new Error('USER_CANCELLED'));
          },
        });
      });

      handlePurchaseFlow(PURCHASE_TYPE.APPLE_PAY, () => {
        if (!applePaySession) {
          throw new Error('Apple Pay session not initialized');
        }

        applePaySession.begin();
        // Promise resolution value is defined above in `onPaymentAuthorized`
        return promise;
      });
    };

    return <ApplePayPurchaseButton onClick={handleClickApplePayButton} />;
  }

  function renderGooglePayPurchaseButton(
    trackPurchaseButtonClick: VoidFunction
  ) {
    const handleClickGooglePayButton = () => {
      if (!googlePayContext) {
        throw new Error('Google Pay is not available');
      }

      const { googlePaymentInstance, googlePaymentClient } =
        googlePayContext.googlePay;

      const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest(
        {
          transactionInfo: {
            currencyCode: 'USD',
            totalPriceStatus: 'FINAL',
            totalPrice: insurance.adjustedTotalWithInsurance.toString(),
          },
        }
      );

      trackPurchaseButtonClick();

      return (
        googlePaymentClient
          .loadPaymentData(paymentDataRequest)
          // @ts-expect-error - Google Pay is not cohesively typed
          .then((response) => googlePaymentInstance.parseResponse(response))
          // @ts-expect-error - Google Pay is not cohesively typed
          .then((parsedResponse) => {
            handlePurchaseFlow(PURCHASE_TYPE.GOOGLE_PAY, () => ({
              paymentOptions: {
                nonce: parsedResponse.nonce,
              },
              insuranceOptions: insurance.getPurchaseOptions(),
              insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
            }));
          })
          // @ts-expect-error - Google Pay is not cohesively typed
          .catch((err) => {
            const listingPath = listing.getPath(event);
            if (err.status === 'CANCELED') {
              console.info('Google pay session cancelled by user');
              navigate({
                pathname: `${listingPath}/checkout`,
                search: location.search,
              });
            }

            console.error('Error during Google Pay: ', err);
          })
      );
    };

    return <GooglePayPurchaseButton onClick={handleClickGooglePayButton} />;
  }

  function renderPayPalPurchaseButton(trackPurchaseButtonClick: VoidFunction) {
    const handlePaymentSuccess = (data: PaymentSuccessData) => {
      handlePurchaseFlow(PURCHASE_TYPE.PAYPAL_PAY, () => ({
        paymentOptions: {
          nonce: data.nonce,
        },
        insuranceOptions: insurance.getPurchaseOptions(),
        insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
      }));
    };

    return (
      <PayPalPurchaseButton
        total={insurance.adjustedTotalWithInsurance}
        onPaymentSuccess={handlePaymentSuccess}
        onClick={trackPurchaseButtonClick}
      />
    );
  }

  function renderAffirmPurchaseButton(
    trackPurchaseButtonClick: (
      modifyTracker?: (tracker: ClickTracker) => void
    ) => void
  ) {
    function handleClick() {
      setIsAffirmFormModalOpen(true);
      trackPurchaseButtonClick((tracker: ClickTracker) => {
        tracker.payload({ [PAYLOAD.TYPE]: T_ACTIONS.OPEN_AFFIRM_MODAL });
      });
    }

    return <AffirmPurchaseButton handleClick={handleClick} />;
  }

  function renderCreditCardPurchaseButton(
    trackPurchaseButtonClick: (
      modifyTracker?: (tracker: ClickTracker) => void
    ) => void
  ) {
    const onClick = async () => {
      const paymentCard = getPaymentCard();

      if (!paymentCard) {
        console.warn('Unable to determine the selected card');
        return;
      }

      setIsSubmitting(true);

      try {
        // @ts-expect-error fetchUserDeviceVerified has not been converted to TS
        await dispatch(fetchUserDeviceVerified(user, paymentCard.token));
      } catch (userDeviceVerifiedError: unknown) {
        // @ts-expect-error - TS doesn't know that userDeviceVerifiedError is an Error
        if (userDeviceVerifiedError.status === 404) {
          trackPurchaseButtonClick((tracker) => {
            tracker.targetPageType(Click.TARGET_PAGE_TYPES.CVV_VERIFICATION());
          });

          setIsVerifyCVVModalOpen(true);
          analytics.track(new View(View.PAGE_TYPES.CVV_VERIFICATION()));
          setIsSubmitting(false);

          return;
        }

        console.error(
          new Error('Error verifying user device', {
            cause: userDeviceVerifiedError,
          })
        );
      } finally {
        setIsSubmitting(false);
      }

      trackPurchaseButtonClick();
      initiateCreditCardFlow(paymentCard.token);
    };

    return (
      <Button onClick={onClick} disabled={isPurchaseButtonDisabled()}>
        <CheckCircleFilledIcon fill="currentColor" />
        Place Order
      </Button>
    );
  }

  function renderPurchaseButton() {
    const trackPurchaseButtonClick = (
      modifyTracker?: (tracker: ClickTracker) => void
    ) => {
      if (selectedPurchaseType && isPurchaseType(selectedPurchaseType)) {
        const tracker = new ClickTracker()
          .interaction(Click.INTERACTIONS.PURCHASE_BUTTON())
          .payload(
            insurance.getPurchaseTrackingPayload(
              PAYMENT_METHOD_NAMES[selectedPurchaseType]
            )
          );

        if (modifyTracker) {
          modifyTracker(tracker);
        }

        analytics.track(
          new Click(_merge({}, clickContext, tracker.json() as Click))
        );
      }
    };

    switch (selectedPurchaseType) {
      case PURCHASE_TYPE.AFFIRM_PAY: {
        if (insurance.adjustedTotalWithInsurance < 50) {
          return renderCreditCardPurchaseButton(trackPurchaseButtonClick);
        }
        return renderAffirmPurchaseButton(trackPurchaseButtonClick);
      }
      case PURCHASE_TYPE.APPLE_PAY: {
        return renderApplePayPurchaseButton(trackPurchaseButtonClick);
      }
      case PURCHASE_TYPE.GOOGLE_PAY: {
        return renderGooglePayPurchaseButton(trackPurchaseButtonClick);
      }
      case PURCHASE_TYPE.PAYPAL_PAY: {
        return renderPayPalPurchaseButton(trackPurchaseButtonClick);
      }
      case PURCHASE_TYPE.CREDIT_CARD_ON_FILE:
      default: {
        return renderCreditCardPurchaseButton(trackPurchaseButtonClick);
      }
    }
  }

  async function handleVerifyCVV(
    tokenize: BraintreeTokenize,
    setFieldError: (
      fieldName: HostedFieldsHostedFieldsFieldName,
      errorMessage: string
    ) => void
  ) {
    const braintreeCVVNonce = await tokenize()
      .then((payload) => payload.nonce)
      .catch((tokenizeError) => {
        setFieldError('cvv', 'An unknown error occurred');
        console.error(
          new Error('Error tokenizing braintree hosted fields instance', {
            cause: tokenizeError,
          })
        );
      });

    if (braintreeCVVNonce) {
      const paymentCard = getPaymentCard();
      if (!paymentCard) {
        console.warn('Payment card not found');
        return;
      }

      const tracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.CONFIRM())
        .sourcePageType(Click.SOURCE_PAGE_TYPES.CVV_VERIFICATION());

      // @ts-expect-error - TS doesn't know that the action creator returns a promise
      await dispatch(verifyCVV(user, paymentCard.object_id, braintreeCVVNonce))
        .then(() => {
          analytics.track(
            new Click(
              _merge(
                { status: 'succeeded' },
                clickContext,
                tracker.json() as Click
              )
            )
          );
          initiateCreditCardFlow(paymentCard.token);
        })
        // @ts-expect-error - TS doesn't know that userDeviceVerifiedError is an Error
        .catch((cvvVerificationError) => {
          const HTTP_STATUS = {
            FORBIDDEN: 403,
            CONFLICT: 409,
            UNPROCESSABLE_ENTITY: 422,
          };

          switch (cvvVerificationError.status) {
            case HTTP_STATUS.CONFLICT: {
              // Device association already exists, treat as success
              initiateCreditCardFlow(paymentCard.token);
              break;
            }
            case HTTP_STATUS.UNPROCESSABLE_ENTITY: {
              // Non-matching CVV, try again
              setFieldError('cvv', 'Invalid CVV');
              analytics.track(
                new Click(
                  _merge(
                    { status: 'failed', attempt: invalidCVVAttempts },
                    clickContext,
                    tracker.json() as Click
                  )
                )
              );
              setInvalidCVVAttempts(invalidCVVAttempts + 1);
              break;
            }
            case HTTP_STATUS.FORBIDDEN: {
              tracker.targetPageType(Click.TARGET_PAGE_TYPES.CHECKOUT_V3());
              analytics.track(
                new Click(
                  _merge(
                    { status: 'failed' },
                    clickContext,
                    tracker.json()
                  ) as unknown as Click
                )
              );
              setIsInvalidCVVModalOpen(true);
              setIsVerifyCVVModalOpen(false);
              break;
            }
            default: {
              setFieldError('cvv', 'An unknown error occurred');
              console.error(
                new Error('Error verifying CVV', {
                  cause: cvvVerificationError,
                })
              );
              break;
            }
          }
        });
    }
  }

  function closeVerifyCVVModal() {
    setIsVerifyCVVModalOpen(false);
  }

  function closeInvalidCVVModal() {
    fetchCreditCards({ force: true });
    setIsInvalidCVVModalOpen(false);
  }

  function handleEditWallet() {
    setIsEditCardModalOpen(true);
  }

  const ref = React.useRef(null);

  function isDarkTheme(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- This is fired in the `useEffect` below and therefore will not always be truthy
    if (ref.current === null) {
      return true;
    }

    const themeColor = getComputedStyle(ref.current)
      .getPropertyValue('--color-background-default-primary')
      .trim();

    return themeColor === '#1c1c20'; // TODO: Can we read our CSS files for this?
  }

  function HeadingExtra() {
    const [fill, setFill] = useState<string | undefined>(undefined);

    // SSR does not have access to `getComputedStyle`, need to wait until mount
    useEffect(() => {
      if (typeof window === 'undefined') {
        return;
      }

      setFill(isDarkTheme() ? 'var(--color-text-default-primary)' : undefined);
    }, []);

    return (
      <>
        {Object.keys(creditCard.cards).length > 0 ? (
          <button className={styles['edit-button']} onClick={handleEditWallet}>
            Edit
          </button>
        ) : (
          <span className={styles['secured-text']}>
            Secured by <Paypal fill={fill} height={10.17} width={45.13} />
          </span>
        )}
      </>
    );
  }

  return (
    <CheckoutContainer isPurchasing>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <section className={styles.payment} ref={ref}>
        <div className={styles['contact-container']}>
          <div className={styles.contact}>
            <span className={styles['contact-title']}>Contact</span>
            <span className={styles['contact-email']}>{user.email}</span>
          </div>
        </div>
        <div className={styles['payment-methods']}>
          <div className={styles['section-heading-container']}>
            <h2 className={styles['section-heading']}>Choose How to Pay</h2>
            <HeadingExtra />
          </div>
          <PaymentMethods
            addCardTitle={getAddCardTitle(
              Object.keys(creditCard.cards).length > 0
            )}
            adjustedTotal={adjustedTotal}
            classNameList={{
              component: {
                container: styles['radio-group'],
                ...pick(styles, [
                  'affirm-icon',
                  'google-pay-icon',
                  'affirm-promo-message',
                  'card-desc',
                  'card-exp',
                  'lastfour',
                  'paypal-icon',
                  'star',
                ]),
              },
              radio: radioInputClassList,
              radioButton: pick(styles, [
                'add-icon',
                'content',
                'icon',
                'radio-card-button',
                'title',
              ]),
            }}
            creditCards={Object.values(creditCard.cards)}
            handleShowAddCardModal={handleShowAddCardModal}
            iconColor="currentColor"
            addIconColor="currentColor"
            isApplePayAvailable={isApplePayAvailable()}
            isGooglePayAvailable={isGooglePayAvailable()}
            isLoading={isFetchingCards || isSubmitting}
            onSelectPaymentMethod={handleSelectPaymentMethod}
            selectedPaymentMethod={getSelectedPaymentMethod()}
            showCreditCardOptions
            withCheckmark={false}
          />
        </div>
        {shouldShowInsurance ? (
          <InsuranceOptions
            classNameList={{
              header: {
                'title-row': styles['header-title-row'],
                component: styles['insurance-options'],
                description: styles['header-description'],
                header: styles['insurance-header'],
                title: styles['header-title'],
                ...pick(styles, ['body', 'header-button']),
              },
              component: pick(styles, [
                'coverage-list',
                'group-icon',
                'icon',
                'modal-body-heading',
                'modal-body',
                'placeholder',
                'policy-wording-link',
                'price',
                'quote',
                'radio-group',
                'recommended-tab',
                'recommended-text',
                'social-proof',
                'terms-and-conditions',
                'title',
              ]),
              radio: radioInputClassList,
            }}
            description={
              <>
                Get <span className={styles['highlight']}>100% reimbursed</span>{' '}
                for your purchase if you can't attend your event due to illness,
                injury, involuntary unemployment, and more.
              </>
            }
            insurance={insurance}
            labels={{
              yes: 'Yes, Add Ticket Protection',
              viewDetails: 'View Details',
            }}
            user={user}
            withCheckmark={false}
            withShieldIcon={false}
            disclaimer={insuranceDisclaimer}
          />
        ) : null}
        {shouldShowMLBOptInSection && (
          <MLBOptIn
            isOptedIn={isMLBMarketingOptedIn}
            performerName={event.getPrimaryPerformer().name}
            onOptInChange={() =>
              setIsMLBMarketingOptedIn(!isMLBMarketingOptedIn)
            }
          />
        )}
        <div className={styles.checkout}>
          <p className={styles['terms-and-conditions']}>
            {'By purchasing you agree to our '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/terms-of-service"
            >
              Terms of Use
            </a>
            {' and '}
            <a target="_blank" rel="noopener noreferrer" href="/privacy-policy">
              Privacy Policy
            </a>
            .
          </p>
          <div
            className={styles['pay']}
            data-total={
              event.currencyPrefix +
              formatPriceWithComma(insurance.adjustedTotalWithInsurance)
            }
          >
            {renderPurchaseButton()}
          </div>
        </div>
      </section>
      <Dialog
        isOpen={isAddCardModalOpen}
        title="Add New Card"
        onClose={() => setIsAddCardModalOpen(false)}
      >
        <NewCardForm theme="light" onSubmit={handleAddCardSubmit} />
      </Dialog>
      <EditCardModal
        cards={creditCard.cards}
        clickTracker={{}}
        deleteCard={deleteUserCreditCard}
        isCheckoutV3
        isOpen={isEditCardModalOpen}
        onClose={() => {
          setIsEditCardModalOpen(false);
        }}
      />
      {getPaymentCard() && (
        <Dialog
          isOpen={isVerifyCVVModalOpen}
          onClose={closeVerifyCVVModal}
          title="Confirm Your CVV"
        >
          <VerifyCvvForm
            card={getPaymentCard()}
            onSubmit={(tokenize, setFieldError) =>
              handleVerifyCVV(tokenize, setFieldError)
            }
          />
        </Dialog>
      )}

      <Dialog
        isOpen={isInvalidCVVModalOpen}
        onClose={closeInvalidCVVModal}
        title="Invalid CVV"
      >
        <p>
          You have exceeded 10 attempts to verify this device. Please select
          another payment method or add a new one.
        </p>

        <Button onClick={closeInvalidCVVModal}>Close</Button>
      </Dialog>

      <Dialog
        title="Get Started"
        isOpen={isAffirmFormModalOpen}
        onClose={() => setIsAffirmFormModalOpen(false)}
      >
        <AffirmCheckoutForm
          onSubmit={(formData) => handleAffirmCheckout(formData)}
          user={user}
        />
      </Dialog>
    </CheckoutContainer>
  );
}

const loader: DataLoader =
  ({ store: { dispatch } }) =>
  async () => {
    await dispatch(fetchDisclosures());

    return null;
  };

CheckoutV3.loader = loader;

export default CheckoutV3;
