import { createAction } from '@reduxjs/toolkit';
import type { AppDispatch, AsyncThunk } from 'store';

import { getLocaleConfigs } from 'services/localeConfigs/localeConfigs.service';
import { GetLocaleConfigsRequestParams, GetLocaleConfigsResponse } from 'types';

export const fetchLocaleConfigsSuccess = createAction<{
  response: GetLocaleConfigsResponse;
  localeConfigKey: string;
}>('LocaleConfigs/GetLocaleConfigsSuccess');

export const fetchLocaleConfigsFailure = createAction<unknown>(
  'LocaleConfigs/GetLocaleConfigsFailure'
);

export const fetchLocaleConfigs =
  (params: GetLocaleConfigsRequestParams): AsyncThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await getLocaleConfigs(params);
      const localeConfigKey = `${params.country}-${params.state}`;

      if (!response) {
        throw new Error('null response from GET Locale Configs request');
      }

      dispatch(fetchLocaleConfigsSuccess({ response, localeConfigKey }));
    } catch (error) {
      dispatch(fetchLocaleConfigsFailure(error));
    }
  };
