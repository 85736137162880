import React from 'react';

import config from '../config/variants';
import Checkout from '../pages/Checkout/Checkout';
import CheckoutV3 from '../pages/CheckoutV3/CheckoutV3';
import { DataLoader } from '../routes/routes.utils';
import { useVariantService } from '../services/variants';
import { fetchDisclosures } from '../store/modules/resources/resource.actions';

function CheckoutExperiment() {
  const variantService = useVariantService();

  if (
    variantService.hasExperimentValue(
      config.experiments.checkoutV3,
      'theme',
      'dark',
      'light'
    )
  ) {
    return <CheckoutV3 />;
  }

  return <Checkout />;
}

const loader: DataLoader =
  ({ store: { dispatch } }) =>
  async () => {
    await dispatch(fetchDisclosures());

    return null;
  };

CheckoutExperiment.loader = loader;

export default CheckoutExperiment;
