import { useMemo } from 'react';
import { useStatsigClient } from '@statsig/react-bindings';

import config, { Providers } from '../../config/variants';
import { StatsigDriver, VariantDriverContract } from '../../drivers/variants';

export default function useVariantService<Provider extends Providers>(
  driver?: Provider
): VariantDriverContract {
  const { client: statsig } = useStatsigClient();

  return useMemo(() => {
    const provider = driver ?? config.provider;

    switch (provider) {
      // Disable eslint rule for this line because there's only one provider at the moment
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      case 'statsig':
        return new StatsigDriver(statsig) as VariantDriverContract;
      default:
        throw new Error(`Variant driver '${provider}' does not exist.`);
    }
  }, [driver, statsig]);
}
