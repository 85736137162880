import { createReducer } from '@reduxjs/toolkit';

import type { GetListingsV3Params } from 'services/listings/listings.service';
import { ListingV3, ListingV3DisplayGroup, RequestStatus } from 'types';

import {
  fetchListingsV3Failure,
  fetchListingsV3Request,
  fetchListingsV3Success,
  setIsListMapHarmonyEnabled,
  setZoomLevel,
  updateListingsV3Params,
} from './actions';

export interface ListingsV3State {
  status: RequestStatus;
  params?: GetListingsV3Params;
  listings: ListingV3[];
  displayGroups: ListingV3DisplayGroup[];
  availableLots: number[];
  zoomLevel: number;
  error?: unknown;
  isListMapHarmonyEnabled: boolean;
  curationId?: string;
}

export const initialState: ListingsV3State = {
  status: 'idle',
  params: undefined,
  listings: [],
  displayGroups: [],
  availableLots: [],
  zoomLevel: 0,
  error: undefined,
  isListMapHarmonyEnabled: false,
  curationId: undefined,
};

const listingsV3Reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchListingsV3Request, (state, action) => {
      if (state.params?.eventId !== action.payload.eventId) {
        state.zoomLevel = action.payload.zoom;
        state.isListMapHarmonyEnabled = false;
      }

      state.params = action.payload;
      state.status = 'loading';
      state.error = undefined;
    })
    .addCase(fetchListingsV3Success, (state, action) => {
      // make sure we are only loading data for the last requested event
      if (state.params?.eventId === action.payload.eventId) {
        const { response } = action.payload;

        state.status = 'success';
        state.listings = response.listings;
        state.availableLots = response.available_lots;
        state.displayGroups = response.display_groups;
        state.curationId = response['X-Gt-Curation-Id'];
      }
    })
    .addCase(fetchListingsV3Failure, (state, action) => {
      state.status = 'failure';
      state.error = action.payload;
    })
    .addCase(updateListingsV3Params, (state, action) => {
      state.params = action.payload;
    })
    .addCase(setZoomLevel, (state, action) => {
      state.zoomLevel = action.payload;
    })
    .addCase(setIsListMapHarmonyEnabled, (state, action) => {
      state.isListMapHarmonyEnabled = action.payload;
    });
});

export default listingsV3Reducer;
