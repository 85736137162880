import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import IconButton from 'ui/IconButton';

import { Hover, HoverTracker, PAYLOAD, useAnalyticsContext } from 'analytics';
import Link from 'components/Link/Link';
import { device, useMediaQuery } from 'hooks';
import { ChevronIcon, CloseLineIcon } from 'icons';
import { Deal, Disclosure, Listing } from 'models';
import { listingImagePropsSelector } from 'store/modules/resources/resource.selectors';
import colors from 'styles/colors.constants';
import { formatPriceWithComma } from 'utils/number';

import { useListingPrice } from '../../../hooks';
import PinContainer from '../../../PinContainer';
import GalleryViewTooltip from '../../GalleryViewTooltip';
import BackgroundPin from '../BackgroundPin/BackgroundPin';
import SeatMapPinHead from '../SeatMapPinHead';

import SeatMapPinExclusives from './SeatMapPinExclusives';

import pinStyles from '../../../PinContainer/PinContainer.module.scss';

interface TrackingData {
  price: number;
  isAllInPriceActive: boolean;
  listingIndex: number;
  isListingOverlay: boolean;
  dealName: string;
  isListingDetails: boolean;
}

interface SeatMapPinProps {
  isMLBEvent: boolean;
  listing: Listing;
  listingIndex: number;
  onPinClick: (args: { listing: Listing; tracking: TrackingData }) => void;
  onPinHover: (args: { listing: Listing; tracking: TrackingData }) => void;
  isHighlighted: boolean;
  isListingDetails: boolean;
  mapImage: { width: number; height: number };
  venueName: string;
  allDeals: Partial<Record<string, Deal>>;
  allDisclosures: Record<string, Disclosure>;
  isAllInPriceActive: boolean;
  priceWithPromoApplied: number;
  onListingClose: VoidFunction;
  isPurchaseListing: boolean;
  initiateCheckoutFlow: VoidFunction;
  isListingFlow: boolean;
  observeMapHarmony: boolean;
  isCheckout: boolean;
  isHarmonyPlusOverlay: boolean;
  showGalleryViewTooltip: boolean;
  scale?: number;
  isBackgroundPin?: boolean;
  selectedBackgroundPin: string;
  onBackgroundPinSelect?: (listingId: string) => void;
}

const SeatMapPin = ({
  isMLBEvent,
  listing,
  listingIndex,
  onPinClick,
  onPinHover,
  isHighlighted,
  isListingDetails,
  mapImage,
  venueName,
  allDeals,
  allDisclosures,
  isAllInPriceActive,
  priceWithPromoApplied,
  onListingClose,
  isPurchaseListing,
  initiateCheckoutFlow,
  isListingFlow,
  observeMapHarmony,
  isCheckout,
  isHarmonyPlusOverlay,
  showGalleryViewTooltip,
  scale = 1,
  isBackgroundPin = false,
  selectedBackgroundPin = '',
  onBackgroundPinSelect,
}: SeatMapPinProps) => {
  const renderStartTime = useRef(performance.now());
  const analytics = useAnalyticsContext();
  const [isBackgroundPinHovered, setIsBackgroundPinHovered] = useState(false);
  const price = useListingPrice({
    listing,
    isAllInPriceActive,
    isListingDetails,
    priceWithPromoApplied,
    isPurchaseListing,
  });
  const isMobile = useMediaQuery(device.down.md);

  const deal = listing.dealType && allDeals[listing.dealType];

  const isFocusOnPurchaseListing = isListingDetails && isPurchaseListing;
  const isLargePin = isFocusOnPurchaseListing && !isHighlighted;
  const isListingOverlay = isHarmonyPlusOverlay && isListingFlow;
  const trackingData: TrackingData = {
    price,
    isAllInPriceActive,
    listingIndex,
    isListingOverlay,
    dealName: deal?.slug || '',
    isListingDetails,
  };

  useEffect(() => {
    const renderEndTime = performance.now();
    const renderDuration = Math.round(renderEndTime - renderStartTime.current);

    Sentry.setMeasurement(`mappin_render_time`, renderDuration, 'millisecond');
  }, []);

  const handleClick = () => {
    if (onBackgroundPinSelect) {
      onBackgroundPinSelect(isBackgroundPin ? listing.id : '');
    }

    if (isBackgroundPin) {
      setIsBackgroundPinHovered(false);
    }

    onPinClick({ listing, tracking: trackingData });
  };

  const handleBackgroundPinHover = () => {
    setIsBackgroundPinHovered(true);
    const tracker = new HoverTracker().payload({
      [PAYLOAD.IS_BACKGROUND_PIN]: true,
    });
    analytics.track(new Hover(tracker.json()));
  };

  const handleBackgroundPinLeave = () => {
    setIsBackgroundPinHovered(false);
  };

  const handlePinHover = () => {
    onPinHover({ listing, tracking: trackingData });
  };

  const conditionalClassNames: Parameters<typeof classNames>[0] = {
    [pinStyles['zindex-selected-pin']]: isLargePin || isHighlighted,
  };

  function getBigPinButton() {
    if (isListingOverlay) {
      return (
        <IconButton
          onClick={initiateCheckoutFlow}
          icon={
            <ChevronIcon
              width="12"
              height="12"
              direction="right"
              color={colors.white}
            />
          }
        />
      );
    }

    if (isCheckout || (isListingFlow && !isHarmonyPlusOverlay)) {
      return (
        <IconButton
          onClick={onListingClose}
          icon={<CloseLineIcon width="16" height="16" fill={colors.white} />}
        />
      );
    }
  }

  const dealInfo = listing.dealType && allDeals[listing.dealType];

  const isBackgroundPinSelected = selectedBackgroundPin === listing.id;
  const isBackgroundPinHighlighted =
    isBackgroundPin && (isBackgroundPinHovered || isHighlighted);

  const isMobileBackgroundContainer =
    isMobile && isBackgroundPin && !isHighlighted;

  const isDesktopBackgroundContainer =
    !isMobile &&
    isBackgroundPin &&
    !isBackgroundPinHovered &&
    !isBackgroundPinSelected &&
    !isHighlighted &&
    !isLargePin;

  const isBackgroundPinContainer =
    isMobileBackgroundContainer || isDesktopBackgroundContainer;

  const pinDealPosition = deal?.position ?? 0;
  const pinZIndex = isBackgroundPinContainer ? 0 : pinDealPosition + 1;

  return (
    <PinContainer
      listing={listing}
      mapImage={mapImage}
      isLargePin={isLargePin}
      pinContainerClassNames={classNames(conditionalClassNames)}
      scale={scale}
      isBackgroundPinContainer={isBackgroundPinContainer}
      zIndex={pinZIndex}
    >
      <Link
        data-map-pin
        onClick={isCheckout || isPurchaseListing ? undefined : handleClick}
        rel="nofollow"
      >
        <SeatMapPinHead listing={listing} observeMapHarmony={observeMapHarmony}>
          {isLargePin ? (
            <SeatMapPinExclusives
              isSelected={isHighlighted}
              text={
                isListingFlow ? `$${formatPriceWithComma(price)}` : undefined
              }
              bigPinConfig={{
                imageProps: listingImagePropsSelector(listing, venueName),
                button: getBigPinButton(),
              }}
              deal={deal}
            />
          ) : (
            <GalleryViewTooltip
              disableTooltip={!showGalleryViewTooltip}
              listing={listing}
              isAllInPrice={isAllInPriceActive}
              allDisclosures={allDisclosures}
              isMLBEvent={isMLBEvent}
              onHover={handlePinHover}
              isBackgroundPin={isBackgroundPin}
            >
              {isBackgroundPinContainer ? (
                <BackgroundPin
                  handleBackgroundPinHover={handleBackgroundPinHover}
                />
              ) : (
                <SeatMapPinExclusives
                  isSelected={isHighlighted || isBackgroundPinHighlighted}
                  text={`$${formatPriceWithComma(price)}`}
                  deal={dealInfo}
                  isBackgroundPinHighlighted={isBackgroundPinHighlighted}
                  handleBackgroundPinLeave={handleBackgroundPinLeave}
                />
              )}
            </GalleryViewTooltip>
          )}
        </SeatMapPinHead>
      </Link>
    </PinContainer>
  );
};

export default Sentry.withProfiler(React.memo(SeatMapPin), {
  name: 'SeatMapPin',
});
