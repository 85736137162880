import React from 'react';

import ModalManager from 'components/Modals/ModalManager';
import OrderDetails from 'components/OrderDetails/OrderDetails';
import CheckoutShieldIcon from 'icons/CheckoutShieldIcon';
import { LogoColor } from 'logos';
import { Guarantee } from 'pages/Checkout/components/Guarantee';
import CheckoutFooter from 'pages/Order/components/CheckoutFooter';

import Notifications from '../../../components/Notifications/Notifications';
import { device, useMediaQuery } from '../../../hooks';

import styles from './CheckoutContainer.module.scss';

type Props = Readonly<{
  children: React.ReactNode;
  isPurchasing?: boolean;
  hideDetailsOnMobile?: boolean;
  showCart?: boolean;
}>;

function CheckoutContainer({
  children,
  isPurchasing = false,
  hideDetailsOnMobile = false,
  showCart = true,
}: Props) {
  const isNotMobile = useMediaQuery(device.up.checkoutV3Sm);
  const showFooterAndOrderDetails = !hideDetailsOnMobile || isNotMobile;

  return (
    <main className={styles.component}>
      <header className={styles.header}>
        <section className={styles['header-inner']}>
          <a className={styles.logo} href="/">
            <LogoColor height="20" width="126" />
          </a>

          <div className={styles['title-area']}>
            <h1 className={styles.title}>Checkout</h1>
            <CheckoutShieldIcon />
          </div>
        </section>
      </header>

      <section className={styles.checkout}>{children}</section>

      {showFooterAndOrderDetails && (
        <section className={styles.details}>
          <OrderDetails showMap={isNotMobile} showCart={showCart} />
        </section>
      )}

      {showFooterAndOrderDetails && isPurchasing && <Guarantee />}

      {showFooterAndOrderDetails && <CheckoutFooter />}

      <ModalManager />
      <Notifications />
    </main>
  );
}

export default CheckoutContainer;
