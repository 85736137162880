import React from 'react';

import styles from './BackgroundPin.module.scss';

function BackgroundPin({
  handleBackgroundPinHover,
}: {
  handleBackgroundPinHover?: () => void;
}) {
  return (
    <div
      className={styles['seat-map-pin-background']}
      onMouseEnter={handleBackgroundPinHover}
    />
  );
}

export default BackgroundPin;
