import { PrecomputedEvaluationsInterface } from '@statsig/react-bindings';
import { merge } from 'lodash';

import config from '../../config/variants';
import {
  Experiments as ExperimentNames,
  Gates as GateNames,
} from '../../types/variants';

import VariantDriverContract, {
  DynamicConfigValue,
  ExperimentValue,
  ExperimentValueKeys,
  ParameterStoreValue,
} from './VariantDriverContract';

export default class StatsigDriver implements VariantDriverContract {
  #client: PrecomputedEvaluationsInterface;

  constructor(client: PrecomputedEvaluationsInterface) {
    this.#client = client;
  }

  capture(
    event: string,
    value?: number | string,
    data?: Record<string, string>
  ): void {
    this.#debug(`📝 Log event '${event}'`);
    this.#client.logEvent(event, value, data);
  }

  #debug(...args: unknown[]): void {
    if (!config.debug) {
      return;
    }

    console.info(...args);
  }

  getConfig<Name extends string, Field extends string, Fallback = unknown>(
    name: Name,
    field: Field,
    fallback?: Fallback
  ): Partial<DynamicConfigValue<Name, Field, Fallback>> {
    const value = this.#client.getDynamicConfig(name).get(field, fallback);
    this.#debug(`⚙️ Dynamic config '${name}.${field}' retrieved.`, value);

    return value as Partial<DynamicConfigValue<Name, Field, Fallback>>;
  }

  getExperiment<Name extends ExperimentNames>(
    name: Name
  ): Partial<ExperimentValue<Name>> {
    const experiment = this.#client.getExperiment(name);

    this.#debug(
      `🔬 Experiment '${name}' retrieved`,
      `Status: ${experiment.details.reason}`,
      experiment.value
    );

    return experiment.value as Partial<ExperimentValue<Name>>;
  }

  getExperimentValue<
    Name extends ExperimentNames,
    Key extends ExperimentValueKeys<Name> & string,
    Returns = ExperimentValue<Name>[Key],
  >(name: Name, key: Key, defaultValue?: Returns): Returns {
    const experiment = this.#client.getExperiment(name);
    const value = experiment.get<Returns>(key, defaultValue);

    this.#debug(
      `🔬 Experiment '${name}.${key}' retrieved`,
      `Status: ${experiment.details.reason}`,
      value
    );

    return value as Returns;
  }

  hasExperimentValue<
    Name extends ExperimentNames,
    Key extends ExperimentValueKeys<Name> & string,
  >(name: Name, key: Key, ...value: unknown[]): boolean {
    return value.includes(this.getExperimentValue(name, key, null));
  }

  getParameter<Name extends string, Field extends string, Fallback = unknown>(
    name: string,
    field: string,
    fallback?: unknown
  ): Partial<ParameterStoreValue<Name, Field, Fallback>> {
    const value = this.#client.getParameterStore(name).get(field, fallback);
    this.#debug(`⚙️ Parameter '${name}.${field}' retrieved.`, value);

    return value as Partial<ParameterStoreValue<Name, Field, Fallback>>;
  }

  hasFeature(name: GateNames): boolean {
    const gate = this.#client.checkGate(name);
    this.#debug(`🏁 Feature flag '${name}' retrieved`, gate);

    return gate;
  }

  async updateUser(user: {
    email?: string;
    id: string;
    sessionId?: string;
  }): Promise<void> {
    this.#debug('👤 Updating user', user.id);

    const customIds: Record<string, string> = {};

    if (user.sessionId) {
      customIds.sessionId = user.sessionId;
    }

    const currentUser = this.#client.getContext().user;

    await this.#client.updateUserAsync({
      appVersion: currentUser.appVersion,
      custom: merge(currentUser.custom, { platform: 'web' }),
      customIDs: merge(currentUser.customIDs, customIds),
      email: user.email,
      userAgent: window.navigator.userAgent,
      userID: user.id,
    });
  }
}
