import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAnalyticsContext, View } from 'analytics';
import OrderDetailContents from 'components/OrderDetails/OrderDetailContents';
import ChevronIcon from 'icons/ChevronIcon';
import { Listing } from 'models';
import { getBetMGMEligibility } from 'pages/Order/helpers';

import OrderConfirmationDetails from './OrderConfirmationDetails';
import OrderConfirmationExtras from './OrderConfirmationExtras';

import styles from '../../Order.module.scss';

const completePurchaseToListing = (completePurchase, fullEvent) => {
  return new Listing(
    {
      delivery_type: completePurchase.delivery_info.delivery_type,
      available_lots: [],
      disclosures: completePurchase.spot.disclosures,
      display_savings: null,
      event_id: fullEvent.event.id,
      id: null,
      lot_attributes: null,
      price: {
        prefee:
          (completePurchase.charge.total_amount -
            completePurchase.charge.fees -
            completePurchase.charge.sales_tax) /
          completePurchase.tickets.length,
        sales_tax: completePurchase.charge.sales_tax,
        total:
          completePurchase.charge.total_amount /
          completePurchase.tickets.length,
        face_value: null,
      },
      row: completePurchase.spot.row,
      section: completePurchase.spot.section,
      section_group: completePurchase.spot.section_group,
      signature: null,
      source: null,
      spot: { ...completePurchase.spot, position: { x: 0, y: 0 } },
      ticket_type: completePurchase.tickets[0].type,
      ticket: completePurchase.tickets[0],
      transfer_type: null,
      view_url: completePurchase.spot.view_url,
    },
    completePurchase.tickets.length
  );
};

const OrderConfirmationV3 = ({
  completePurchase,
  showConfirmation,
  deliveryDisplayProps,
  fullEvent,
  orderStatus,
  user,
  parkingEvent,
  getDeliveryDisplayText,
  getOrderedListItems,
  transactionId,
}) => {
  const analytics = useAnalyticsContext();
  const navigate = useNavigate();
  useEffect(() => {
    analytics.track(
      new View(
        View.PAGE_TYPES.ORDER_CONFIRMATION_V3(
          completePurchase.id,
          !!parkingEvent,
          completePurchase?.charge.promo_code
        )
      )
    );
  }, []);

  const isBetMGMEligible = getBetMGMEligibility(
    fullEvent.venue.state,
    fullEvent.event.category
  );

  const listing = completePurchaseToListing(completePurchase, fullEvent);
  const transferComplete = completePurchase.steps.some(
    (step) => step.status === 'completed' && step.done
  );
  let deliveryDetailsTitle = showConfirmation
    ? deliveryDisplayProps.confirmationCardTitle
    : deliveryDisplayProps.orderDetailTitle;
  if (transferComplete && deliveryDisplayProps.postDeliveryTitle) {
    deliveryDetailsTitle = deliveryDisplayProps.postDeliveryTitle;
  }
  const deliveryDisplayText = getDeliveryDisplayText();
  const orderedList = getOrderedListItems();

  const orderNumber = completePurchase.confirmation_number || '* please call *';
  return (
    <div className={styles['confirmation-container']}>
      <OrderConfirmationDetails
        deliveryDetailsTitle={
          deliveryDetailsTitle || deliveryDisplayProps.getAppTitle
        }
        deliveryDisplayText={
          deliveryDisplayText || deliveryDisplayProps.getAppText
        }
        orderedList={orderedList}
        orderStatus={orderStatus}
        orderNumber={orderNumber}
        user={user}
        completePurchase={completePurchase}
        eventSlug={fullEvent.getPrimaryPerformer().slug}
        fullEvent={fullEvent}
      />
      <div className={styles['order-detail-container']}>
        <button
          className={styles['back-button']}
          onClick={() => navigate('/my-tickets')}
        >
          <ChevronIcon
            height={18}
            width={18}
            direction="left"
            color="currentColor"
          />
          <span className={styles['back-text']}>My Tickets</span>
        </button>
        <OrderDetailContents
          event={fullEvent}
          listing={listing}
          purchase={completePurchase}
          showMap={false}
          showSeatView
          hideFaceValue
        />
      </div>
      <OrderConfirmationExtras
        fullEvent={fullEvent}
        parkingEvent={parkingEvent}
        transactionId={transactionId}
        insuranceInfo={completePurchase.insurance_info}
        purchase={completePurchase}
        isBetMGMEligible={isBetMGMEligible}
      />
    </div>
  );
};

OrderConfirmationV3.propTypes = {
  completePurchase: PropTypes.object.isRequired,
  showConfirmation: PropTypes.bool,
  deliveryDisplayProps: PropTypes.object.isRequired,
  fullEvent: PropTypes.object.isRequired,
  orderStatus: PropTypes.string,
  parkingEvent: PropTypes.object,
  user: PropTypes.object.isRequired,
  getDeliveryDisplayText: PropTypes.func.isRequired,
  getOrderedListItems: PropTypes.func.isRequired,
  transactionId: PropTypes.string,
};

export default OrderConfirmationV3;
