import React, { Component } from 'react';
import { connect } from 'react-redux';
import { redirect } from 'react-router-dom';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import PropTypes from 'prop-types';

import { Click, ClickTracker, withAnalyticsContext } from 'analytics';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import {
  Sidebar,
  SIDEBAR_VIEWS,
  SidebarProfile,
  SidebarPromoCodes,
} from 'components/Sidebar';
import SupportLink from 'components/SupportLink/SupportLink';
import redirectUnauthenticatedUser from 'helpers/redirectUnauthenticatedUser';
import { ChevronIcon } from 'icons';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import PromoCodes from 'pages/MyTickets/components/PromoCodes/PromoCodes';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { fetchUserPromoCodes } from 'store/modules/user/actions';
import {
  selectUserDetails,
  selectUserPromos,
} from 'store/modules/user/user.selectors';
import colors from 'styles/colors.constants';

import Profile from './components/Profile/Profile';

import styles from './MyAccount.module.scss';

@withAnalyticsContext
class MyAccount extends Component {
  static propTypes = {
    user: PropTypes.shape({
      email: PropTypes.string,
      tmmobile_first_name: PropTypes.string,
    }),
    activePromos: PropTypes.array,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);

    this.handleSetActiveView = this.handleSetActiveView.bind(this);
    this.hideMobileActiveView = this.hideMobileActiveView.bind(this);
    this.state = {
      hasSelectedMobileActiveView: false,
      activeView: SIDEBAR_VIEWS.PROFILE,
    };
  }

  handleSetActiveView(view) {
    const {
      appContext: {
        state: { isMobile },
      },
      analyticsContext,
    } = this.props;
    const targetPage =
      view === SIDEBAR_VIEWS.PROFILE ? 'PROFILE' : 'REDEEM_CODE';

    const clickTracker = new ClickTracker()
      .targetPageType(Click.TARGET_PAGE_TYPES[targetPage]())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.MY_ACCOUNT())
      .interaction(Click.INTERACTIONS.NONE());

    analyticsContext.track(new Click(clickTracker.json()));

    this.setState((state) => ({
      ...state,
      activeView: view,
      hasSelectedMobileActiveView: isMobile,
    }));
  }

  getActiveComponent() {
    const { activePromos, user } = this.props;

    switch (this.state.activeView) {
      case SIDEBAR_VIEWS.PROMO:
        return (
          <PromoCodes
            activePromos={activePromos}
            goBack={this.hideMobileActiveView}
          />
        );
      case SIDEBAR_VIEWS.PROFILE:
      default:
        return <Profile user={user} goBack={this.hideMobileActiveView} />;
    }
  }

  getMobileView() {
    if (this.state.hasSelectedMobileActiveView) {
      return this.getActiveComponent();
    } else {
      return (
        <Sidebar>
          <SidebarProfile
            onSetActiveView={this.handleSetActiveView}
            isActive={this.state.activeView === SIDEBAR_VIEWS.PROFILE}
            icon={
              <ChevronIcon
                width="16"
                height="16"
                direction="right"
                color={colors.white}
              />
            }
          />
          <SidebarPromoCodes
            onSetActiveView={this.handleSetActiveView}
            isActive={this.state.activeView === SIDEBAR_VIEWS.PROMO}
            icon={
              <ChevronIcon
                width="16"
                height="16"
                direction="right"
                color={colors.white}
              />
            }
          />
          <SupportLink
            icon={
              <ChevronIcon
                width="16"
                height="16"
                direction="right"
                color={colors.white}
              />
            }
          />
        </Sidebar>
      );
    }
  }

  hideMobileActiveView() {
    this.setState((state) => ({
      ...state,
      hasSelectedMobileActiveView: false,
    }));
  }

  render() {
    const {
      user,
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    if (!user) return null;

    return (
      <ContainerTemplate
        header={
          <MinimalHeader
            search
            showCategories
            showHamburger
            showAccount
            hideMobileHeader={this.state.hasSelectedMobileActiveView}
          />
        }
        footer={<GTFooter />}
      >
        <HeadTitle title="My Account" />

        <div className={styles['my-account-container']}>
          <div
            className={classNames(styles['my-account-page'], {
              [styles['hide-title']]: this.state.hasSelectedMobileActiveView,
            })}
          >
            <div className={styles['my-account-title-section']}>
              <h1 className={styles['my-account-title']}>My Account</h1>
              <h2 className={styles['my-account-subtitle']}>{user.email}</h2>
            </div>
            <hr className={styles['my-account-divider']} />
            <div className={styles['page-content']}>
              {!isMobile ? (
                <>
                  <div className={styles['sidebar-container']}>
                    <Sidebar isMobile={false}>
                      <SidebarProfile
                        onSetActiveView={this.handleSetActiveView}
                        isActive={
                          this.state.activeView === SIDEBAR_VIEWS.PROFILE
                        }
                      />
                      <SidebarPromoCodes
                        onSetActiveView={this.handleSetActiveView}
                        isActive={this.state.activeView === SIDEBAR_VIEWS.PROMO}
                      />
                      <SupportLink />
                    </Sidebar>
                  </div>
                  {this.getActiveComponent()}
                </>
              ) : (
                this.getMobileView()
              )}
            </div>
          </div>
        </div>
      </ContainerTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: selectUserDetails(state),
    activePromos: selectUserPromos(state),
  };
};

const MyAccountWrapper = connect(mapStateToProps)(withAppContext(MyAccount));

const loader =
  ({ store: { dispatch, getState } }) =>
  async ({ request }) => {
    const state = getState();
    const user = selectUserDetails(state);
    if (!user) {
      redirectUnauthenticatedUser({
        location: new URL(request.url),
        asyncRedirect: redirect,
      });
    } else {
      await Promise.all([
        dispatch(fetchMetros()),
        dispatch(fetchUserPromoCodes()),
      ]);
    }

    return null;
  };

MyAccountWrapper.loader = loader;

export default MyAccountWrapper;
