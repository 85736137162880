import appConfig from './config';
import { envIsLikeDev } from './environments';

export type Providers = 'statsig';

const config = {
  debug: envIsLikeDev(appConfig.env),
  experiments: {
    checkoutV3: 'checkout_v3',
    webBackgroundPins: 'web_background_pins',
  },
  provider: 'statsig',
  gates: {
    smsLoginEnabled: 'smsloginenabled',
    howDidYouHearAboutUsSurvey: 'how_did_you_hear_about_us_survey',
    insurance: 'insurance',
    brazeEnabled: 'braze_enabled',
  },
  statsig: {
    credentials: {
      client_token: appConfig.STATSIG_CLIENT_TOKEN,
    },
  },
} as const;

export default config;
