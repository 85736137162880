import React from 'react';
import { connect } from 'react-redux';
import { useTransformEffect } from 'react-zoom-pan-pinch';
import PropTypes from 'prop-types';

import config from 'config/variants';
import { Deal, Disclosure } from 'models';
import { isBackgroundPin } from 'pages/Event/helpers';
import { useVariantService } from 'services/variants';
import { userPromosForListingPriceSelector } from 'store/modules/user/user.selectors';

import SeatMapPin from './SeatMapPin';

const SeatMapPins = ({
  listings,
  highlightedListingId = null,
  isListingDetailsPage = false,
  onPinClick,
  onPinHover,
  venueName,
  isMLBEvent,
  allDeals,
  allDisclosures,
  isAllInPriceActive,
  priceWithPromoApplied,
  onListingClose,
  focusedPins = [],
  isHarmonyPlusOverlay,
  currentListingId,
  initiateCheckoutFlow,
  isListingFlow,
  observeMapHarmony = false,
  isCheckout,
  mapImage,
  isMapLoaded,
  onLastPinRender,
  showGalleryViewTooltip,
  zoomLevel,
}) => {
  const performanceLastPin = React.useRef(null);
  const [currentScale, setCurrentScale] = React.useState(1);
  const [selectedBackgroundPin, setSelectedBackgroundPin] = React.useState('');
  const variantService = useVariantService();
  const isBackgroundPinsEnabled = variantService.hasExperimentValue(
    config.experiments.webBackgroundPins,
    'background_pins',
    'background_pins_enabled'
  );

  useTransformEffect(({ state }) => {
    const { scale } = state;

    if (scale > 0) {
      setCurrentScale(scale);
    }
  });

  if (!isMapLoaded || (mapImage.height === -1 && mapImage.width === -1)) {
    return null;
  }

  const shouldShowHighlighted = (listing) => {
    if (isListingDetailsPage && isHarmonyPlusOverlay) return false;
    const showFocusedPins =
      !highlightedListingId && focusedPins.includes(listing.id);
    return listing.id === highlightedListingId || showFocusedPins;
  };

  return (
    <>
      {listings.map((listing, index) => {
        if (!listing) return null;

        const isHighlighted = shouldShowHighlighted(listing);

        const showBackgroundPin =
          isBackgroundPinsEnabled && isBackgroundPin(listing, zoomLevel);

        if (index === listings.length - 1 && !performanceLastPin.current) {
          // Time Between LoadStartTime and the last pin
          performanceLastPin.current = Math.round(Date.now());
          onLastPinRender(performanceLastPin.current);
        }

        return (
          <SeatMapPin
            key={listing.id}
            listing={listing}
            isMLBEvent={isMLBEvent}
            listingIndex={index}
            onPinClick={onPinClick}
            onPinHover={onPinHover}
            isHighlighted={isHighlighted}
            isListingDetails={isListingDetailsPage}
            mapImage={mapImage}
            allDeals={allDeals}
            allDisclosures={allDisclosures}
            isAllInPriceActive={isAllInPriceActive}
            venueName={venueName}
            priceWithPromoApplied={priceWithPromoApplied}
            onListingClose={onListingClose}
            isPurchaseListing={currentListingId === listing.id}
            initiateCheckoutFlow={initiateCheckoutFlow}
            isListingFlow={isListingFlow}
            isCheckout={isCheckout}
            observeMapHarmony={observeMapHarmony}
            isHarmonyPlusOverlay={isHarmonyPlusOverlay}
            showGalleryViewTooltip={showGalleryViewTooltip}
            scale={currentScale}
            isBackgroundPin={showBackgroundPin}
            selectedBackgroundPin={selectedBackgroundPin}
            onBackgroundPinSelect={setSelectedBackgroundPin}
          />
        );
      })}
    </>
  );
};

function mapStateToProps(state) {
  return {
    priceWithPromoApplied: userPromosForListingPriceSelector(state),
  };
}

export default connect(mapStateToProps)(React.memo(SeatMapPins));

SeatMapPins.propTypes = {
  listings: PropTypes.array,
  isListingDetailsPage: PropTypes.bool,
  highlightedListingId: PropTypes.string,
  onPinClick: PropTypes.func.isRequired,
  onPinHover: PropTypes.func.isRequired,
  onListingClose: PropTypes.func,
  venueName: PropTypes.string,
  isMLBEvent: PropTypes.bool.isRequired,
  allDeals: PropTypes.objectOf(PropTypes.instanceOf(Deal)),
  allDisclosures: PropTypes.objectOf(PropTypes.instanceOf(Disclosure)),
  isAllInPriceActive: PropTypes.bool,
  priceWithPromoApplied: PropTypes.number,
  isMapLoaded: PropTypes.bool,
  focusedPins: PropTypes.array,
  isHarmonyPlusOverlay: PropTypes.bool,
  currentListingId: PropTypes.string,
  initiateCheckoutFlow: PropTypes.func,
  isListingFlow: PropTypes.bool,
  observeMapHarmony: PropTypes.bool,
  isCheckout: PropTypes.bool,
  mapImage: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  onLastPinRender: PropTypes.func.isRequired,
  showGalleryViewTooltip: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number,
};
