import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useVariantService } from 'services/variants';
import {
  selectIsNewUser,
  selectUserDetails,
} from 'store/modules/user/user.selectors';

import config from '../config/config';
import variantConfig from '../config/variants';

const BrazeContext = createContext();
function BrazeProvider({ children, user, isNewUser }) {
  const [braze, setBraze] = useState();
  const variantService = useVariantService();
  const brazeEnabled = variantService.hasFeature(
    variantConfig.gates.brazeEnabled
  );

  useEffect(() => {
    if (brazeEnabled && user) {
      // since Braze SDK is only meant to run in browser environments, we
      // need to dynamically import on the client only. this is safe because
      // no React component lifecycle methods run on the server.
      // see: https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#ssr
      import('@braze/web-sdk').then((brazeSDK) => {
        const isBrazeConfigured = brazeSDK.initialize(config.BRAZE.API_KEY, {
          baseUrl: config.BRAZE.BASE_URL,
        });

        if (isBrazeConfigured) {
          brazeSDK.changeUser(user.id);

          if (isNewUser) {
            brazeSDK.getUser().setEmail(user.email);
          }

          setBraze(brazeSDK);
        } else {
          console.warn('Braze SDK configuration was unsuccessful');
        }
      });
    }
  }, [brazeEnabled, user, isNewUser]);

  const contextValue = useMemo(() => {
    if (!braze) {
      return {};
    }

    return {
      /**
       * Log a successful purchase to Braze.
       * @param {Object} params
       * @param {FullEvent} params.fullEvent
       * @param {Listing} params.listing
       * @param {string} params.transactionId
       * @param {(string|null)} [params.promoCode=null]
       */
      logPurchaseToBraze: ({
        fullEvent,
        listing,
        promoCode = null,
        transactionId,
      }) => {
        braze.logPurchase(
          'Completed Order',
          listing.getFaceValue(),
          'USD',
          listing.quantity,
          {
            purchaseId: transactionId,
            clientType: 'client_web',
            promoCodeUsed: promoCode,
            eventDate: `${fullEvent.event.datetimeUtc}Z`,
            eventId: fullEvent.id,
            eventName: fullEvent.getName(),
            venueId: fullEvent.venue.id,
            venueSlug: fullEvent.venue.slug,
            listingId: listing.id,
            performerSlug: fullEvent.getPrimaryPerformer().slug,
            creditAmount: null, // gametime credit not used on web
          }
        );
      },
    };
  }, [braze]);

  return (
    <BrazeContext.Provider value={contextValue}>
      {children}
    </BrazeContext.Provider>
  );
}
BrazeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
  isNewUser: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isNewUser: selectIsNewUser(state),
    user: selectUserDetails(state),
  };
}
export default connect(mapStateToProps)(BrazeProvider);

/**
 * Hook that returns a configured Braze SDK instance. The Braze instance
 * will only be available after configuration so consumers may need to
 * wait before using.
 */
export function useBraze() {
  const context = useContext(BrazeContext);
  if (!context) {
    throw new Error('useBraze may only be used within BrazeProvider');
  }
  return context;
}

/**
 * HOC to inject a configured Braze SDK instance into wrapped class
 * components. Prefer the useBraze hook for function components. The Braze
 * instance will only be available after configuration so consumers may
 * need to wait before using.
 */
export function withBraze(Component) {
  const WithBrazeComponent = (props) => (
    <BrazeContext.Consumer>
      {(contextValue) => <Component {...props} {...contextValue} />}
    </BrazeContext.Consumer>
  );

  const displayName = Component.displayName || Component.name || 'Component';
  WithBrazeComponent.displayName = `withBraze(${displayName})`;

  return WithBrazeComponent;
}
