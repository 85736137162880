import { MOBILE_VIEW } from 'types/event';
import { sortObjectsByProperty } from 'utils/arrays';

import { CANADIAN_METROS, CANADIAN_PROVINCES } from './Event.constants';

export const getPageViewType = ({ query = null, isMobile = false }) => {
  if (query && query.view) {
    return query.view;
  } else if (isMobile) {
    return MOBILE_VIEW.LIST;
  } else {
    return MOBILE_VIEW.HYBRID;
  }
};

/*
 * We are only showing all-in pricing on the event page for Canadian provinces by default. When
 * retrieving this information from the back end, it's the state of the venue we read despite Canada
 * using provinces colloquially.
 */
export const isCanadianProvince = (venueState) => {
  if (!venueState) return false;
  return CANADIAN_PROVINCES.includes(venueState);
};

/*
 * It is likely more regulatory changes will come in this space.
 * So, it may be better to keep the distinction between all-in and face-value, despite the repeated logic
 */
export const isFaceValueState = (venueState) => {
  if (!venueState) return false;
  const faceValueRegions = [...CANADIAN_PROVINCES, 'NY'];
  return faceValueRegions.includes(venueState);
};

export const getCurrencyPrefix = (fullEvent, selectedMetro) => {
  if (CANADIAN_METROS.includes(selectedMetro.toLowerCase())) {
    return 'USD $';
  }

  return fullEvent.currencyPrefix;
};

export const sortListings = (listings) =>
  sortObjectsByProperty(
    listings,
    'price.total',
    (priceA, priceB) => priceB - priceA
  );

export const getExpiredEventTitle = (isUpcomingEvent) => {
  const actionMessage = isUpcomingEvent
    ? 'See Upcoming Events.'
    : 'See Similar Performers.';
  return `Event Expired, ${actionMessage}`;
};

export const getLastRoute = (lastRouteLocation) =>
  lastRouteLocation
    ? lastRouteLocation.pathname + lastRouteLocation.search
    : '';

export const isBackgroundPin = (listing, zoom) =>
  listing.data.zoom_configs[zoom]?.pin_type === 'background';
