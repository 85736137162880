import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Button from 'ui/Button/Button';

import { TicketClickTracker } from 'analytics';
import DeliveryIcon from 'components/DeliveryFormat/DeliveryIcon/DeliveryIcon';
import Link from 'components/Link/Link';
import { getTicketURL } from 'components/OrderDetail/components/TicketCard/helpers';
import { useSeatDescription } from 'components/SeatDescription/useSeatDescription';
import { CheckCircleFilledIcon, ChevronIcon, TicketIcon } from 'icons';
import { FullEvent } from 'models';
import { ACTIVE_RESALE_STATES } from 'pages/MyTickets/resale.constants';
import { ORDER_STEP_STATUS } from 'pages/Order/constants';
import {
  areNonTransferTicketsReady,
  getPerformerMessage,
} from 'pages/Order/helpers';
import { useAppDispatch } from 'store';
import {
  DELIVERY_FORMATS,
  deliveryFormatForPurchase,
} from 'store/datatypes/DELIVERY_FORMATS';
import { DELIVERY_TYPES } from 'store/datatypes/DELIVERY_TYPES';
import { MODALS, showModal } from 'store/modules/modals/modals';
import { Purchase, Ticket, UserModel } from 'types';
import { searchStringToQueryObject } from 'utils/url';

import styles from './OrderConfirmationDetails.module.scss';

type OrderedListItem = {
  text: string;
  dataKey: string;
  email?: string;
};

type Props = {
  user: UserModel;
  orderStatus: string;
  orderedList?: OrderedListItem[];
  orderNumber: string;
  deliveryDetailsTitle: string;
  deliveryDisplayText: string;
  completePurchase: Purchase;
  eventSlug: string;
  fullEvent: FullEvent;
};

const OrderConfirmationDetails = ({
  deliveryDisplayText,
  deliveryDetailsTitle,
  orderedList,
  orderNumber,
  orderStatus,
  user,
  completePurchase,
  eventSlug,
  fullEvent,
}: Props) => {
  const {
    spot,
    event_id,
    id: purchaseId,
    tickets,
    delivery_info,
  } = completePurchase;

  const dispatch = useAppDispatch();
  const location = useLocation();

  const deliveryFormat = deliveryFormatForPurchase(completePurchase);

  const handleClick = () => {
    dispatch(showModal(MODALS.GET_APP));
  };

  function renderOwnedTicket(ticket: Ticket) {
    const { id: ticketId, type, type_info, url, seat } = ticket;
    const clickTracker = new TicketClickTracker(ticketId, purchaseId, event_id);

    const { title, seat: seatText } = useSeatDescription({
      sectionGroup: spot.section_group,
      section: spot.section,
      row: spot.row,
      ticket,
    });

    return (
      <Link
        className={styles['ticket-card']}
        key={ticketId}
        to={getTicketURL(
          ticketId,
          eventSlug,
          deliveryFormat,
          type === 'url' || !!(type_info?.display_pdf_first && url)
        )}
        target="_blank"
        rel="noopener noreferrer"
        clickTracker={clickTracker}
      >
        <div className={styles['ticket-card-description']}>
          <TicketIcon fill="currentColor" />
          <div className={styles['ticket-card-text']}>
            <span className={styles['ticket-card-title']}>{title}</span>
            {!!seat && seat !== '*' && (
              <span className={styles['ticket-card-subtitle']}>{seatText}</span>
            )}
          </div>
        </div>
        <div className={styles['chevron-container']}>
          <ChevronIcon
            width={14}
            height={14}
            color="currentColor"
            direction="right"
          />
        </div>
      </Link>
    );
  }

  function renderReadyTickets() {
    const ownedTickets = tickets.filter((ticket) => {
      if (!ticket.resale_info) return false;
      return !ACTIVE_RESALE_STATES.includes(ticket.resale_info.status);
    });

    return (
      <>
        <span className={styles['owned-tickets-title']}>
          {ownedTickets.length === 1
            ? '1 Ticket'
            : `${ownedTickets.length} Tickets`}
        </span>
        {ownedTickets.map((ticket) => renderOwnedTicket(ticket))}
      </>
    );
  }

  function getPerformerMessageText() {
    if (deliveryFormat === DELIVERY_FORMATS.MLB_ORDER) return '';

    const isPending = orderStatus === ORDER_STEP_STATUS.PENDING;
    const showConfirmation = !!searchStringToQueryObject(location.search)
      .confirmation;
    const transferComplete = completePurchase.steps.some(
      (step) => step.status === 'completed' && step.done
    );
    return getPerformerMessage(
      fullEvent,
      isPending,
      showConfirmation,
      transferComplete
    );
  }

  const isHardDeliveryType =
    delivery_info.delivery_type === DELIVERY_TYPES.hard;
  const allNonTransferTicketsReady = areNonTransferTicketsReady(tickets);

  const shouldRenderReadyTickets =
    allNonTransferTicketsReady && !isHardDeliveryType;
  const isPending = orderStatus === ORDER_STEP_STATUS.PENDING;
  const subtitle = isPending
    ? "We're processing your order and will reach out if we need any additional info. You should receive an order confirmation email at "
    : 'Your purchase is complete and we sent a confirmation email with details to ';

  return (
    // will also need to move this wrapper div to parent
    <div className={styles['order-confirmation-container']}>
      <div className={styles['order-status']}>
        {shouldRenderReadyTickets ? (
          renderReadyTickets()
        ) : (
          <>
            {/* are other statuses expected if so what icons are associated */}
            <div
              className={classNames(
                styles['order-confirmation-status'],
                styles[orderStatus]
              )}
            >
              {orderStatus === ORDER_STEP_STATUS.CONFIRMED && (
                <CheckCircleFilledIcon
                  height="16"
                  width="16"
                  fill="currentColor"
                />
              )}
              <span className={styles.status}>Order {orderStatus}</span>
            </div>
            <span className={styles.subtitle}>
              {subtitle}
              <span className={styles.email}>{user.email}</span>.
              <br />
              {getPerformerMessageText()}
            </span>
          </>
        )}
        <div className={styles.divider} />
        <span className={styles['order-confirmation-number']}>
          Order Number: <span className={styles.number}>{orderNumber}</span>
        </span>
      </div>
      {!shouldRenderReadyTickets && (
        <div className={styles['order-confirmation-details-card']}>
          <div className={styles['order-confirmation-details']}>
            <DeliveryIcon
              deliveryFormat={deliveryFormat}
              iconProps={{ fill: 'currentColor' }}
            />
            <div className={styles['delivery-details']}>
              <h2 className={styles['delivery-details-title']}>
                {deliveryDetailsTitle}
              </h2>
              <p className={styles['delivery-details-text']}>
                {deliveryDisplayText}
              </p>
              {orderedList && orderedList.length > 0 && (
                <ol className={styles['extended-icon-list']}>
                  {orderedList.map((item) => (
                    <li
                      key={item.text}
                      className={styles['extended-icon-list-item']}
                    >
                      {item.text}
                      {item.email && (
                        <span className={styles['extended-icon-email']}>
                          {item.email}
                        </span>
                      )}
                    </li>
                  ))}
                </ol>
              )}
              {isPending && (
                <p className={styles['extended-link-text']}>
                  {'For more info, visit our '}
                  <Link
                    href="https://support.gametime.co/en_us/categories/transfer-ticket-questions-HyQpK83nq"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles['extended-link']}
                  >
                    FAQ
                  </Link>
                  .
                </p>
              )}
            </div>
            <Button buttonClass="secondary" onClick={handleClick}>
              Get the App
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderConfirmationDetails;
