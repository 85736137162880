import Cookies from 'js-cookie';

import config from 'config/config';
import { UserModel } from 'types';
import { HttpError, NetworkError } from 'utils/errors';

import { parseJsonResponseBody } from './helpers';

const DEFAULT_TIMEOUT = 10_000;
const STATUS_CODE_NO_CONTENT = 204;

export type URLSearchParamsInit = ConstructorParameters<
  typeof URLSearchParams
>[0];

type AuthUser = Pick<UserModel, 'id' | 'session_token'>;

export type RequestOptions<B = void, R = unknown> = {
  /**
   * HTTP methods used by Mobile API endpoints, defaults to "GET"
   */
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  /**
   * Endpoint path, relative to the base URL, beginning with a forward slash
   */
  path: `/${string}`;
  /**
   * optional search parameters to send with the request, will be serialized
   * and appended to the URL as a query string
   */
  searchParams?: URLSearchParamsInit;
  /**
   * optional headers to send with the request — note that these are applied
   * last and will override any base headers with the same name
   */
  headers?: HeadersInit;
  /**
   * optional authenticated user to send with the request
   */
  authUser?: AuthUser;
  /**
   * optional body to send with the request
   */
  body?: B;
  /**
   * optional timeout in milliseconds, defaults to 10 seconds
   */
  timeout?: number;
  /**
   * optional AbortController instance to cancel an in-progress request
   */
  abortController?: AbortController;
  /**
   * optional function to parse the response body, defaults to `parseJson`
   */
  parseResponse?: (body: string, headers: Headers) => Promise<R | null>;
};

export class HttpClient {
  baseURL: string;
  baseHeaders?: Headers;

  constructor(baseURL: string, baseHeaders?: HeadersInit) {
    this.baseURL = baseURL;
    this.baseHeaders = new Headers(baseHeaders);

    this.baseHeaders.set('pf', 'web');
    this.baseHeaders.set('Content-Type', 'application/json');
  }

  getHeaders(requestHeaders?: HeadersInit, authUser?: AuthUser) {
    const headers = new Headers(this.baseHeaders);

    if (authUser) {
      headers.set('session_token', authUser.session_token);
      headers.set('user_id', authUser.id);
    }

    if (__CLIENT__) {
      const webId = Cookies.get(config.cookiesConfig.WEB_USER_ID.name);
      if (webId) {
        headers.set('web-id', webId);
      }

      const webSessionId = Cookies.get(
        config.cookiesConfig.WEB_SESSION_ID.name
      );
      if (webSessionId) {
        headers.set('web-session-id', webSessionId);
      }
    }

    if (requestHeaders) {
      if (requestHeaders instanceof Headers) {
        requestHeaders.forEach((value, key) => {
          headers.set(key, value);
        });
      } else if (Array.isArray(requestHeaders)) {
        requestHeaders.forEach(([key, value]) => {
          if (value) {
            headers.set(key, value);
          }
        });
      } else {
        Object.entries(requestHeaders).forEach(([key, value]) => {
          if (value) {
            headers.set(key, value);
          }
        });
      }
    }

    return headers;
  }

  getQueryString(query?: URLSearchParamsInit) {
    if (query) {
      const params = new URLSearchParams(query);
      return '?' + params.toString();
    }

    return '';
  }

  /**
   * Make an HTTP request to MobileAPI.
   *
   * @throws {NetworkError} when the request fails due to external circumstances
   * such as network, CORS, or some other cause where there is no response from
   * the server.
   * @throws {HttpError} when the request fails due to an error response from
   * the server.
   * @throws {JsonParseError} when the response body cannot be parsed as JSON.
   * @throws {DOMException} when the request is cancelled using an
   * AbortController or due to timeout.
   * @throws {unknown} when an unexpected error occurs.
   * @returns {Promise<R | null>} resolves with the response body as JSON, or
   * null when the response status is 204 "No Content".
   */
  async request<R = unknown, B = unknown>(
    options: RequestOptions<B, R>
  ): Promise<R | null> {
    const {
      method = 'GET',
      path = '/',
      searchParams,
      headers: requestHeaders,
      authUser,
      body,
      timeout = DEFAULT_TIMEOUT,
      abortController = new AbortController(),
      parseResponse = parseJsonResponseBody,
    } = options;

    const timeoutId = setTimeout(() => {
      abortController.abort(
        new DOMException(
          `Request timed out after ${timeout} milliseconds`,
          'TimeoutError'
        )
      );
    }, timeout);

    const queryString = this.getQueryString(searchParams);
    const requestUrl = `${this.baseURL}${path}${queryString}`;

    try {
      const response = await fetch(requestUrl, {
        method,
        headers: this.getHeaders(requestHeaders, authUser),
        body: body ? JSON.stringify(body) : undefined,
        signal: abortController.signal,
      })
        .catch((error) => {
          // fetch will throw a TypeError if the request fails due to external
          // circumstances such as network, CORS, or some other cause where there
          // is no response from the server. we want to re-throw as NetworkError
          // in these cases so we can identify and handle them separately from
          // errors that do have a response and status code from the server.
          if (error instanceof TypeError) {
            throw new NetworkError(error.message);
          }

          // if a fetch request is cancelled using an AbortController, fetch will
          // throw a DOMException. most environments pass a custom reason as the
          // argument to AbortController.abort(reason?: any), but only as of Oct
          // 2023 is reason a feature of Android browsers.
          // for our own sanity these should always be an instance of DOMException
          // so we can easily identify and handle them.

          throw error;
        })
        .finally(() => {
          clearTimeout(timeoutId);
        });

      if (!response.ok) {
        // response.status > 299 || response.status < 200
        const message = response.statusText || 'HTTP request failed';
        if (
          response.headers.get('content-type')?.includes('application/json')
        ) {
          const body = await response.json().catch(() => undefined);
          throw new HttpError(message, response.status, body);
        } else {
          throw new HttpError(message, response.status);
        }
      }

      // Check if the response is 204 (No Content), if the content is not JSON, or if the content length is zero
      if (
        response.status === STATUS_CODE_NO_CONTENT ||
        !response.headers.get('content-type')?.includes('application/json') ||
        response.headers.get('content-length') === '0'
      ) {
        return null;
      }

      // clone the response before the body is consumed so we can read it again
      // in `parseResponse` if needed

      // Use response.text() instead of response.json() to get the response body as a string
      // This allows us to handle cases where the response body is not valid JSON
      const responseBody = await response.text();

      // Check if the response body is empty
      if (!responseBody || responseBody.length === 0) {
        return null;
      }

      return await parseResponse(responseBody, response.headers);
    } catch (error) {
      const message =
        error instanceof Error && error.message
          ? error.message
          : `HttpClient error from ${requestUrl}`;

      const details = {
        apiUrl: requestUrl,
        searchParams,
        error,
        status: error instanceof HttpError ? error.status : null,
        ip: null as string | null,
      };

      if (this.baseHeaders?.has('X-Gt-Ip')) {
        details.ip = this.baseHeaders.get('X-Gt-Ip');
      }

      console.error(message, JSON.stringify(details, null, 2));
      throw error;
    }
  }
}
