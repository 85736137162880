import React, { useState } from 'react';
import { useTransformEffect } from 'react-zoom-pan-pinch';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { device, useMediaQuery } from 'hooks';
import DividerIcon from 'icons/DividerIcon';
import MinusIcon from 'icons/MinusIcon';
import PlusIcon from 'icons/PlusIcon';
import ResetIcon from 'icons/ResetIcon';

import styles from './SeatMapControls.module.scss';

const SeatMapControls = ({ onZoomIn, onZoomOut, reset, isAnimating }) => {
  const isMobile = useMediaQuery(device.down.md);
  const [currentScale, setCurrentScale] = useState(5);
  useTransformEffect(({ state }) => {
    const { scale } = state;

    if (scale > 0) {
      setCurrentScale(scale);
    }
  });

  return (
    <div className={styles.container}>
      {!isMobile && (
        <div className={styles.controls}>
          <button
            type="button"
            className={styles.button}
            onClick={onZoomIn}
            aria-label="zoom in"
          >
            <PlusIcon />
          </button>
          <DividerIcon style={{ margin: 'auto', width: '80%' }} />
          <button
            type="button"
            className={styles.button}
            onClick={onZoomOut}
            aria-label="zoom out"
          >
            <MinusIcon />
          </button>
        </div>
      )}

      {currentScale > 1 && reset && (
        <button
          type="button"
          className={classNames(styles['reset-button'], {
            [styles['is-animating']]: isAnimating && isMobile,
          })}
          onClick={reset}
          aria-label="reset zoom"
        >
          <ResetIcon />
        </button>
      )}
    </div>
  );
};

SeatMapControls.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  reset: PropTypes.func,
  isAnimating: PropTypes.bool,
};

export default SeatMapControls;
